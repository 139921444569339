import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/browser';

import { OnlineOfferActivationInfo } from '../../api/rest/offers';
import {
    AffiliateLinkLoadingMethod,
    generateNewAffiliateLinkLoadingAttemptData,
    generateNewAffiliateLinkLoadingMechanismData,
    logClickedAffiliateLinkEventsWithoutAuthentication,
} from './affiliateLinkLoading';
import { captureSentryException } from '../common/sentry';

const CLICKED_AFFILIATE_LINK_EVENT_LOGGING_TIMEOUT_IN_MS = 20000;

export enum OfferActivationState {
    loading = 'loading',
    succeeded = 'succeeded',
    adBlockerDetected = 'adBlockerDetected',
    cookieWarningAcknowledgementRequired = 'cookieWarningAcknowledgementRequired',
}

export function useQueryParameters(): {
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
    triggeringEventTimestamp: number | undefined;
    redirectUrl: string | undefined;
    isTemporaryUser: boolean;
    hasAcceptedMerchantCookies: boolean;
} {
    const location = useLocation();
    return React.useMemo(() => {
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
        return {
            triggeringEventPlatform:
                typeof parsedQuery?.triggeringEventPlatform === 'string' ? parsedQuery.triggeringEventPlatform : undefined,
            triggeringEventType:
                typeof parsedQuery?.triggeringEventType === 'string' ? parsedQuery.triggeringEventType : undefined,
            triggeringEventTimestamp: Number.isInteger(Number(parsedQuery?.triggeringEventTimestamp))
                ? Number(parsedQuery?.triggeringEventTimestamp)
                : undefined,
            redirectUrl:
                typeof parsedQuery?.redirectUrl === 'string' && parsedQuery.redirectUrl !== ''
                    ? decodeURIComponent(parsedQuery.redirectUrl)
                    : undefined,
            isTemporaryUser:
                typeof parsedQuery?.isTemporaryUser === 'string' && parsedQuery.isTemporaryUser !== ''
                    ? parsedQuery.isTemporaryUser === 'true'
                    : false,
            hasAcceptedMerchantCookies:
                typeof parsedQuery?.hasAcceptedMerchantCookies === 'string' && parsedQuery.hasAcceptedMerchantCookies !== ''
                    ? parsedQuery.hasAcceptedMerchantCookies === 'true'
                    : false,
        };
    }, [location]);
}

export function useAffiliateLinkUrl({
    userId,
    isTemporaryUser,
    shouldUseDevStack,
    offerActivationInfo,
    triggeringEventPlatform,
    triggeringEventType,
    triggeringEventTimestamp,
    isBackgroundLoading,
}: {
    userId: string;
    isTemporaryUser: boolean;
    shouldUseDevStack: boolean;
    offerActivationInfo: OnlineOfferActivationInfo | undefined;
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
    triggeringEventTimestamp: number | undefined;
    isBackgroundLoading?: boolean;
}) {
    const [affiliateLinkUrl, setAffiliateLinkUrl] = React.useState<string | undefined>(undefined);
    const getAffiliateLinkUrl = React.useCallback(
        async (offerActivationInfo: OnlineOfferActivationInfo) => {
            const affiliateLinkLoadingMechanismData = generateNewAffiliateLinkLoadingMechanismData({
                userId,
                isTemporaryUser,
                offerActivationInfo,
                triggeringEventPlatform,
                triggeringEventType,
                triggeringEventTimestamp,
            });
            if (!affiliateLinkLoadingMechanismData) return;
            const attemptNumber = 1; // We will make only a single attempt to load the affiliate link
            // The affiliate link URL that embeds a Click ID is generated by this operation
            const affiliateLinkLoadingAttemptData = generateNewAffiliateLinkLoadingAttemptData(
                affiliateLinkLoadingMechanismData,
                isBackgroundLoading ? AffiliateLinkLoadingMethod.hiddenTab : AffiliateLinkLoadingMethod.directLoading,
                attemptNumber
            );
            const timeoutPromise: Promise<{ status: 'timeout' }> = new Promise((resolve) => {
                setTimeout(resolve, CLICKED_AFFILIATE_LINK_EVENT_LOGGING_TIMEOUT_IN_MS, { status: 'timeout' });
            });
            // This step is instrumental because it will allow us to retrieve the User ID from the Click ID passed in the affiliate link
            const { status } = await Promise.race([
                logClickedAffiliateLinkEventsWithoutAuthentication(
                    affiliateLinkLoadingAttemptData,
                    offerActivationInfo.hasRedirectUrl,
                    offerActivationInfo.parametersRemovedFromRedirectUrl,
                    shouldUseDevStack
                ),
                timeoutPromise,
            ]);
            if (status === 'success') setAffiliateLinkUrl(affiliateLinkLoadingAttemptData.affiliateLinkUrl);
            // If the `clickedAffiliateLink` event logging fails, using the personalized affiliate link will at least allow us to match the transaction to the user.
            // The drawback is that we will lose several information such as the triggering event type.
            else {
                Sentry.setTags({ userId, triggeringEventPlatform, triggeringEventType });
                if (status === 'timeout')
                    captureSentryException(new Error('The `clickedAffiliateLink` event logging timed out'));
                else captureSentryException(new Error('Failed to log the `clickedAffiliateLink` event'));
                setAffiliateLinkUrl(affiliateLinkLoadingMechanismData.personalizedAffiliateLink);
            }
        },
        [
            userId,
            isTemporaryUser,
            shouldUseDevStack,
            triggeringEventPlatform,
            triggeringEventType,
            triggeringEventTimestamp,
            isBackgroundLoading,
        ]
    );
    React.useEffect(() => {
        if (offerActivationInfo) getAffiliateLinkUrl(offerActivationInfo);
    }, [offerActivationInfo, getAffiliateLinkUrl]);
    return affiliateLinkUrl;
}

export function redirectToAffiliateLink({
    offerActivationInfo,
    affiliateLinkUrl,
}: {
    offerActivationInfo: OnlineOfferActivationInfo | undefined;
    affiliateLinkUrl: string;
}) {
    const parsedQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const initialUrl =
        typeof parsedQuery?.redirectUrl === 'string' && parsedQuery.redirectUrl !== ''
            ? decodeURIComponent(parsedQuery.redirectUrl)
            : undefined;
    if (offerActivationInfo) postOfferActivatedMessageToExtension(offerActivationInfo?.partialOffer.offerId);
    // We don't want to monitor the URL change if there is no `redirectUrl` parameter in the URL or if there is no offer activation info
    if (initialUrl && offerActivationInfo)
        postOfferActivatedInitialUrlMessageToExtension({ initialUrl, offerActivationInfo });
    window.open(affiliateLinkUrl, '_self');
}

type OfferActivatedPayloadExtensionMessage = {
    type: 'joko:offerActivatedFromWebApp';
    payload: { offerId: string };
};

/** This function communicates with the extension to store the last time the offer was used in order to prevent the display of the offer widget when coming from an offer activation link */
function postOfferActivatedMessageToExtension(offerId: string) {
    const message: OfferActivatedPayloadExtensionMessage = { type: 'joko:offerActivatedFromWebApp', payload: { offerId } };
    window.postMessage(message, '*');
}

type OfferActivatedCurrentUrlExtensionMessage = {
    type: 'joko:saveOfferActivatedInitialUrlPayload';
    payload: {
        initialUrl: string;
        offerId: string;
        personalizedAffiliateLink: string | undefined;
        isAutomaticRedirectUrlApplied: boolean;
    };
};

/** This function is used in the extensions for affiliate link loading monitoring purposes */
function postOfferActivatedInitialUrlMessageToExtension({
    initialUrl,
    offerActivationInfo,
}: {
    initialUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
}) {
    const offerId = offerActivationInfo.partialOffer.offerId;
    const personalizedAffiliateLink = offerActivationInfo.personalizedAffiliateLink;
    const message: OfferActivatedCurrentUrlExtensionMessage = {
        type: 'joko:saveOfferActivatedInitialUrlPayload',
        payload: {
            initialUrl,
            offerId,
            personalizedAffiliateLink,
            isAutomaticRedirectUrlApplied: offerActivationInfo.hasRedirectUrl,
        },
    };
    window.postMessage(message, '*');
}

export function sleep(sleepTimeMs: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, sleepTimeMs));
}
