import gql from 'graphql-tag';

export const UserProductDiscoveryConversationHistoryItemFragment = gql`
    fragment UserProductDiscoveryConversationHistoryItem on UserProductDiscoveryConversationHistoryItem {
        conversationId
        createdAt
        conversationNameToDisplay
    }
`;

export type UserProductDiscoveryConversationHistoryItem = {
    __typename: 'UserProductDiscoveryConversationHistoryItem';
    conversationId: string;
    createdAt: string;
    conversationNameToDisplay: string;
};

export const dataIdFromUserProductDiscoveryConversationHistoryItem = (
    userProductDiscoveryConversationHistoryItem: UserProductDiscoveryConversationHistoryItem
) => {
    return `UserProductDiscoveryConversationHistoryItem:${userProductDiscoveryConversationHistoryItem.conversationId}`;
};

export const UserProductDiscoveryMessageWithProductsFragment = gql`
    fragment UserProductDiscoveryMessageWithProducts on UserProductDiscoveryMessageWithProducts {
        messageId
        role
        content
        timestamp
        merchantProductOffers {
            merchantId
            merchantProductOfferId
            originalTitle
            processedWebpageUrl
            mainImageUrls {
                defaultSize
            }
            priceInformation {
                displayPriceAmount {
                    currency
                    valueInCents
                }
            }
            contextualInformation {
                originalMerchantName
            }
        }
    }
`;

interface MerchantProductOffer {
    __typename: 'MerchantProductOffer';
    merchantId: string | null;
    merchantProductOfferId: string | null;
    originalTitle: string;
    processedWebpageUrl: string | null;
    mainImageUrls: {
        defaultSize: string;
    };
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
    contextualInformation: {
        originalMerchantName: string | null;
    };
}

export interface UserProductDiscoveryMessageWithProducts {
    __typename: 'UserProductDiscoveryMessageWithProducts';
    messageId: string | null;
    role: 'system' | 'user' | 'assistant';
    content: string;
    timestamp: number;
    merchantProductOffers: MerchantProductOffer[] | null;
}

export interface UserProductDiscoveryMessageWithProductsList {
    __typename: 'UserProductDiscoveryMessageWithProductsList';
    items: UserProductDiscoveryMessageWithProducts[];
    lastFetchedAt: number | null;
    nextToken: string | null;
}

export const dataIdFromUserProductDiscoveryMessageWithProductsList = (
    productDiscoveryConversationWithProducts: UserProductDiscoveryMessageWithProductsList
) => {
    if (!productDiscoveryConversationWithProducts.items?.length) return;
    const firstMessage = productDiscoveryConversationWithProducts.items[0];
    if (!firstMessage) return;
    // Each message has a unique `messageId` that is a combination of the `conversationId` and its position in the conversation.
    // We use the `conversationId` from the first message to create a unique `dataId`.
    const conversationId = firstMessage.messageId?.split('|')[0];
    if (conversationId) return `UserProductDiscoveryMessageWithProductsList:${conversationId}`;
};
