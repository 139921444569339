import { Moment } from 'moment';

import { LegalUpdateType } from '../api/graphql/fragments/legalUpdates';
import { OfferType } from '../api/graphql/fragments/offers';
import { RewardType } from '../api/graphql/fragments/rewards';
import { input } from 'aws-amplify';

export function formatNumberWithLocaleFormat(
    number: number | string,
    {
        localeFormat,
        shouldNeverUseSingleDecimal = true,
    }: {
        localeFormat: LocalizedFormat;
        shouldNeverUseSingleDecimal?: boolean;
    }
): string {
    let formattedNumber = number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, localeFormat.number.format.delimiter)
        .replace(/\./g, localeFormat.number.format.separator);
    if (
        shouldNeverUseSingleDecimal &&
        formattedNumber.length > 1 &&
        formattedNumber[formattedNumber.length - 2] === localeFormat.number.format.separator
    )
        formattedNumber += '0'; // In order to replace a number with only 1 decimal (e.g. 1,6) into the same number with 2 decimals (e.g. 1,60)
    return formattedNumber;
}

export function formatCurrencyAmountWithLocaleFormat(
    number: number,
    {
        localeFormat,
        shouldNeverUseThreeDecimals,
    }: {
        localeFormat: LocalizedFormat;
        shouldNeverUseThreeDecimals?: boolean;
    }
): string {
    let roundedNumber: number | string = number;
    if (!Number.isInteger(number)) {
        const roundingMultiplicator = number >= 0.01 || shouldNeverUseThreeDecimals ? 100 : 1000; // In order to round down to the 2nd digit for amounts >= 0.01€, and to the 3rd digit otherwise
        roundedNumber = Math.round(number * roundingMultiplicator) / roundingMultiplicator;
    }
    if (localeFormat.currency.shouldSymbolComeInFront)
        return (
            `${localeFormat.currency.symbol}${localeFormat.currency.separator}` +
            `${formatNumberWithLocaleFormat(roundedNumber, { localeFormat })}`
        );
    else
        return (
            `${formatNumberWithLocaleFormat(roundedNumber, { localeFormat })}${localeFormat.currency.separator}` +
            `${localeFormat.currency.symbol}`
        );
}

export type LocalizedFormat = {
    number: {
        format: {
            delimiter: string;
            separator: string;
        };
    };
    currency: {
        symbol: string;
        shouldSymbolComeInFront: boolean;
        separator: string;
    };
};

export type LocalizedTexts = {
    landing: {
        title: string;
        header: {
            alreadySignedUp: string;
            signIn: string;
            presentation: {
                title: string;
                subtitle: string;
                moreMerchants: string;
            };
            signUp: {
                title: string;
                withFacebookButton: string;
                withAppleButton: string;
                withGoogleButton: string;
                withEmailButton: string;
                socialAndEmailSeparator: string;
            };
            onboarding: {
                firstNamePlaceholder: string;
                agePlaceholder: string;
                gender: {
                    text: string;
                    optional: string;
                };
                referrerCode: {
                    link: string;
                    placeholder: string;
                };
                error: {
                    referrerCodeNotValid: string;
                    default: string;
                };
                submitButton: string;
            };
        };
        howItWorks: {
            title: string;
            signUp: {
                title: string;
                subtitle: string;
            };
            useOffer: {
                title: string;
                subtitle: string;
            };
            getRewarded: {
                title: string;
                subtitle: string;
            };
        };
        signUpButton: string;
        testimonials: {
            title: string;
        };
        valueProposition: {
            title: string;
            shopping: {
                title: string;
                subtitle: string;
            };
            customerService: {
                title: string;
                subtitle: string;
            };
            rewards: {
                title: string;
                subtitle: string;
            };
        };
        about: {
            title: string;
            text: string;
        };
        footer: {
            contact: string;
            termsOfService: string;
            privacyPolicy: string;
        };
    };
    auth: {
        intro: {
            paragraph1: {
                title: string;
                message: string;
            };
            paragraph2: {
                title: string;
                message: string;
            };
            paragraph3: {
                title: string;
                message: string;
            };
        };
        signIn: {
            title: string;
            withEmail: {
                subtitle: string;
                email: string;
                password: string;
                resetPassword: string;
                button: string;
                error: {
                    default: string;
                    wrongCredentials: string;
                    socialAccount: string;
                    wrongMfaCode: string;
                };
                mfa: {
                    title: string;
                    subtitle: string;
                    codePlaceholder: string;
                    button: string;
                };
            };
            withFacebook: {
                button: string;
                loading: string;
                error: {
                    default: string;
                };
            };
            withApple: {
                button: string;
                loading: string;
                error: {
                    default: string;
                };
            };
            withGoogle: {
                button: string;
                loading: string;
                error: {
                    default: string;
                };
            };
            divider: string;
            signUp: {
                text: string;
                button: string;
            };
            signInSucceeded: {
                title: string;
                subtitle: string;
                widget: {
                    title: string;
                    subtitle: {
                        part1: string;
                        part2: string;
                    };
                    button: string;
                };
            };
        };
        signUp: {
            title: string;
            subtitle: string;
            withEmailButton: string;
            withFacebookButton: string;
            withAppleButton: string;
            withGoogleButton: string;
            divider: string;
            signIn: {
                text: string;
                button: string;
            };
            withEmail: {
                emailTitle: string;
                emailPlaceholder: string;
                passwordTitle: string;
                passwordPlaceholder: string;
                passwordRequirements: {
                    minCharacters: ({ minCharacters }: { minCharacters: number }) => string;
                    minLowerCase: ({ minLowerCase }: { minLowerCase: number }) => string;
                    minUpperCase: ({ minUpperCase }: { minUpperCase: number }) => string;
                    minNumbers: ({ minNumbers }: { minNumbers: number }) => string;
                };
                termsOfService: string;
                newsletter: string;
                nextButton: string;
                backButton: string;
                error: {
                    emailNotValid: string;
                    passwordNotValid: string;
                    didNotConsentToTermsOfServices: string;
                    alreadyExists: string;
                    default: string;
                };
            };
        };
        resetPassword: {
            emailPage: {
                title: string;
                subtitle: string;
                emailPlaceholder: string;
                nextButton: string;
                backButton: string;
                error: {
                    default: string;
                    unknownEmail: string;
                    unverifiedEmail: string;
                    generatedPassword: string;
                    limitExceeded: string;
                };
            };
            newPasswordPage: {
                title: string;
                subtitle: string;
                codePlaceholder: string;
                passwordPlaceholder: string;
                nextButton: string;
                successMessage: {
                    title: string;
                    text: string;
                    button: string;
                };
                error: {
                    default: string;
                    wrongCode: string;
                    expiredCode: string;
                    limitExceeded: string;
                };
            };
        };
        browserExtensionAutoLogin: {
            loading: string;
        };
    };
    onboarding: {
        progressIndicator: {
            accountCreationStep: string;
            userInfoStep: string;
            referralStep: string;
        };
        userInfo: {
            firstNameTitle: string;
            firstNamePlaceholder: string;
            ageTitle: string;
            agePlaceholder: string;
            genderTitle: string;
            genderSubtitle: string;
            genderDescription: {
                female: string;
                male: string;
                other: string;
            };
            nextButton: string;
        };
        referrerCode: {
            referrerCodeTitle: string;
            referrerCodePlaceholder: string;
            nextButton: string;
            backButton: string;
        };
        loading: string;
    };
    home: {
        navigationBar: {
            searchBarPlaceholder: string;
        };
        offers: {
            offerComponent: {
                cashbackDescription: string;
                multipleRates: {
                    rateUpTo: string;
                };
                button: {
                    activate: string;
                    shop: string;
                };
                expirationDate: ({ quantity, unit }: { quantity: number; unit: 'hours' | 'days' }) => string;
            };
            offerDetails: {
                cashback: {
                    cashbackDescription: string;
                    valueMinCondition: ({ valueMin }: { valueMin: number }) => string;
                };
                multipleRates: {
                    title: string;
                    rateUpTo: string;
                };
                description: {
                    title: ({ offerTitle }: { offerTitle: string }) => string;
                    seeMore: string;
                    websiteLink: string;
                };
                conditions: {
                    howItWorksTitle: string;
                    title: string;
                    generic: ({ offerType, active }: { offerType: OfferType; active: boolean }) => string;
                    offerTypeIcon: {
                        cardLinked: string;
                    };
                };
                button: {
                    activate: string;
                    shop: string;
                    unavailable: string;
                };
            };
            offerSearch: {
                searchResults: ({ searchValue }: { searchValue: string }) => string;
            };
            onlineOfferActivation: {
                offerActivationStatus: {
                    loading: string;
                    succeeded: string;
                    failed: string;
                };
                adBlockerMessage: {
                    title: string;
                    subtitle1: string;
                    subtitle2OnDesktop: string;
                    subtitle2OnMobile: string;
                    supportLink: string;
                    navigationButtons: {
                        retry: string;
                        continue: string;
                    };
                };
                cookieWarning: {
                    withoutAcknowledgmentRequired: { title: string; subtitle: string };
                    withAcknowledgmentRequired: {
                        title: string;
                        subtitle: string;
                        acknowledgeButtonText: string;
                        skipFutureAcknowledgmentText: string;
                    };
                };
            };
            favoriteOffers: {
                pageTitle: string;
                placeholder: {
                    title: string;
                    subtitle: string;
                    exploreButton: string;
                };
            };
        };
        history: {
            title: string;
            tab: {
                all: string;
                pending: string;
            };
            sectionTitle: {
                currentWeek: string;
                lastWeek: string;
                earlier: string;
            };
            information: {
                cashbackDelay: string;
                pendingRecords: string;
            };
            item: {
                title: {
                    referral: string;
                    contest: string;
                    bankTransfer: string;
                    giftCard: string;
                    donation: string;
                    autoDonation: string;
                };
                date: {
                    today: string;
                    yesterday: string;
                    numberOfDaysAgo: ({ numberOfDaysAgo }: { numberOfDaysAgo: number }) => string;
                    date: ({ date }: { date: Moment }) => string;
                };
                subtitle: {
                    bonus: string;
                    contest: string;
                    bankTransfer: ({ amount }: { amount: number }) => string;
                    giftCard: ({ value, name }: { value: number; name: string }) => string;
                    donation: ({ value, name }: { value: number; name: string }) => string;
                };
            };
            dialog: {
                retroactiveCashback: {
                    title: string;
                    subtitle: ({
                        numberOngoingOfferRetroactiveDays,
                    }: {
                        numberOngoingOfferRetroactiveDays: number;
                    }) => string;
                    buttonText: string;
                };
            };
        };
        pointCollection: {
            title: string;
            button: string;
        };

        profile: {
            menu: {
                settingsSection: {
                    title: string;
                    userInfo: string;
                    termsOfService: string;
                    privacyPolicy: string;
                    signOut: string;
                    deleteUser: string;
                };
                supportSection: {
                    title: string;
                    faq: string;
                };
                deleteUserDialog: {
                    title: string;
                    subtitle: string;
                    buttons: {
                        yes: string;
                        no: string;
                    };
                    deletionInProgress: string;
                };
            };
            userInfo: {
                title: string;
                personalizeTrackingConsents: string;
                mainSection: {
                    title: string;
                    email: string;
                    firstName: string;
                    gender: string;
                    unspecifiedGender: string;
                    age: string;
                    birthDate: string;
                    phoneNumber: string;
                    addPhoneNumber: string;
                    verify: {
                        email: string;
                        phoneNumber: string;
                    };
                };
                emailPage: {
                    title: string;
                    emailPlaceholder: string;
                    error: {
                        alreadyExists: string;
                        default: string;
                    };
                    nextButton: string;
                    backButton: string;
                };
                emailVerificationPage: {
                    title: string;
                    text: string;
                    button: string;
                };
                phoneNumberPage: {
                    title: string;
                    phoneNumberPlaceholder: string;
                    error: {
                        alreadyExists: string;
                        default: string;
                    };
                    nextButton: string;
                    backButton: string;
                };
                phoneNumberVerificationPage: {
                    title: string;
                    subtitle: string;
                    codePlaceholder: string;
                    success: {
                        text: string;
                        button: string;
                    };
                    error: {
                        default: string;
                        wrongCode: string;
                        limitExceeded: string;
                    };
                    nextButton: string;
                    backButton: string;
                };
            };
        };
        rewards: {
            rewardPage: {
                bankTransfers: {
                    title: string;
                    subtitle: string;
                    amount: ({ minAmount }: { minAmount: number }) => string;
                };
                giftCards: {
                    sectionTitle: string;
                    itemSubtitle: ({ value }: { value: number }) => string;
                };
                donations: {
                    sectionTitle: string;
                    itemSubtitle: ({ value }: { value: number }) => string;
                };
            };
            rewardDetails: {
                button: {
                    redeem: {
                        giftCard: string;
                        donation: string;
                    };
                    balanceTooLow: string;
                };
                confirmationDialog: {
                    title: string;
                    subtitle: ({
                        rewardType,
                        value,
                        name,
                    }: {
                        rewardType: RewardType;
                        value: number;
                        name: string;
                    }) => string;
                    button: {
                        yes: string;
                        no: string;
                    };
                };
                successDialog: {
                    title: string;
                    subtitle: string;
                    button: string;
                };
                verificationNeededDialog: {
                    text: ({
                        phoneNumberVerified,
                        emailVerified,
                    }: {
                        phoneNumberVerified: boolean;
                        emailVerified: boolean;
                    }) => string;
                    button: {
                        next: string;
                        back: string;
                    };
                };
            };
            bankTransfer: {
                amount: ({ amount }: { amount: number }) => string;
                redeemButton: {
                    default: string;
                    noAmountSelected: string;
                    balanceTooLow: string;
                };
                success: {
                    title: string;
                    subtitle: ({ terms }: { terms: string }) => string;
                    button: string;
                };
            };
            bankDetailsPage: {
                title: string;
                subtitle: ({ terms }: { terms: string }) => string;
                bankDetails:
                    | {
                          currency: 'EUR';
                          firstName: {
                              label: string;
                              placeholder: string;
                          };
                          lastName: {
                              label: string;
                              placeholder: string;
                          };
                          iban: {
                              label: string;
                              placeholder: string;
                          };
                          bic: {
                              label: string;
                              placeholder: string;
                          };
                      }
                    | {
                          currency: 'GBP';
                          fullName: {
                              label: string;
                              placeholder: string;
                          };
                          accountNumber: {
                              label: string;
                              placeholder: string;
                          };
                          sortCode: {
                              label: string;
                              placeholder: string;
                          };
                      }
                    | {
                          currency: 'USD';
                          fullName: {
                              label: string;
                              placeholder: string;
                          };
                          routingNumber: {
                              label: string;
                              placeholder: string;
                          };
                          accountNumber: {
                              label: string;
                              placeholder: string;
                          };
                          accountType: {
                              label: string;
                              placeholder: string;
                          };
                          address: {
                              firstLine: {
                                  label: string;
                                  placeholder: string;
                              };
                              city: {
                                  label: string;
                                  placeholder: string;
                              };
                              postCode: {
                                  label: string;
                                  placeholder: string;
                              };
                              state: {
                                  label: string;
                                  placeholder: string;
                              };
                          };
                      };
                saveOption: string;
                button: string;
                error: {
                    bankDetails:
                        | {
                              currency: 'EUR';
                              noFirstName: string;
                              noLastName: string;
                              noIban: string;
                              incorrectBic: string;
                              incorrectIban: string;
                              unauthorizedCountry: string;
                          }
                        | {
                              currency: 'GBP';
                              noFullName: string;
                              noAccountNumber: string;
                              noSortCode: string;
                          }
                        | {
                              currency: 'USD';
                              noFullName: string;
                              noRoutingNumber: string;
                              noAccountNumber: string;
                              noAccountType: string;
                              invalidAccountType: string;
                              address: {
                                  noFirstLine: string;
                                  noCity: string;
                                  noPostCode: string;
                                  noState: string;
                              };
                          };
                    balanceTooLow: string;
                    verificationNeeded: string;
                    incorrectAmount: string;
                    unknown: string;
                };
                backButton: string;
            };
            iOSDonationRedeem: {
                confirmation: {
                    text1: ({ value, name }: { value: number; name: string }) => string;
                    text2: string;
                    donationAmount: string;
                    close: string;
                    confirmButton: string;
                    cancelButton: string;
                };
                success: {
                    title: string;
                    text1: string;
                    text2: string;
                };
                cancellation: {
                    title: string;
                    text1: string;
                    text2: string;
                };
                error: {
                    title: string;
                    text1: string;
                    text2: string;
                };
            };
            iosAutoDonationConfirmation: {
                confirmationForm: { text: (rewardTitle: string) => string; cancelButton: string; submitButton: string };
                cancelledMessage: { title: (rewardTitle: string) => string; subtitle: string };
                confirmedMessage: { title: string; subtitle: (rewardTitle: string) => string };
            };
        };
        legalUpdates: {
            title: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) => string;
            links: {
                termsOfServiceLink: string;
                privacyPolicyLink: string;
            };
            refuseButton: string;
            acceptButton: {
                withoutConsent: string;
                withConsent: string;
            };
            refusalSection: {
                title: string;
                text1: ({ legalUpdateType }: { legalUpdateType: LegalUpdateType }) => string;
                text2: string;
                confirmButton: string;
                cancelButton: string;
            };
            refusalConfirmationModalText: string;
        };
        floaPaymentPage: {
            title: string;
            subtitle: string;
            autoClose: string;
            buttonName: string;
        };
        bnplTermsOfService: {
            terms: ({ buttonName }: { buttonName: string }) => string;
            and: string;
            floaTermsOfServices: string;
            floaConfidentialityPolicy: string;
            jokoTermsOfServices: string;
            jokoConfidentialityPolicy: string;
        };
    };
    trackingConsents: {
        trackingModal: {
            message: string;
            privacyPolicyLink: string;
            acceptButton: string;
            personalizeButton: string;
        };
        trackingPersonalization: {
            title: string;
            required: string;
            activateAll: string;
            allActivated: string;
            necessaryTrackers: {
                title: string;
                subtitle: string;
                functionalTrackers: string;
                securityTrackers: string;
            };
            performanceTrackers: {
                title: string;
                subtitle: string;
                amplitudeTrackers: string;
            };
            marketingTrackers: {
                title: string;
                subtitle: string;
                amplitudeTrackers: string;
                googleTagManagerTrackers: string;
            };
            submitButton: string;
        };
    };
    mobileSafariExtensionInstallationPage: {
        header: {
            title: string;
            left: string;
            right: string;
        };
        backToAppButton: string;
        activateStep: {
            preamble: {
                banner: string;
                tosConsent: string;
                startButton: string;
            };
            instructions: {
                title: string;
                firstStep: {
                    title1: string;
                    title2: string;
                    subtitle: string;
                };
                secondStep: string;
                thirdStep: string;
            };
        };
        authorizeStep: {
            preamble: {
                startButton: string;
                title: string;
                subtitle: string;
            };
            instructions: {
                title: string;
                firstStep: {
                    title1: string;
                    title2: string;
                    subtitle: string;
                };
                secondStep: string;
                thirdStep: string;
                fourthStep: string;
            };
            instructionsWithSystemSettings: {
                title: string;
                firstStep: string;
                secondStep: string;
                thirdStep: string;
                openSystemSettingsButton: string;
            };
            instructionsWithBanner: {
                firstStep: string;
                secondStep: string;
                thirdStep: string;
                missingBannerButton: string;
            };
            headerTitle: string;
            headerSubtitle: string;
        };
        conclusion: {
            title: string;
            subtitle: string;
            connectInAppSubtitle: string;
            connectInAppButton: string;
            returnToJokoButton: string;
            headerTitle: string;
            headerSubtitle: string;
        };
        missingAuthorization: {
            allWebsitesBeforeRetry: {
                title: string;
                subtitle: string;
                button: string;
            };
            allWebsitesAfterRetry: {
                title: string;
                subtitle: string;
            };
            default: {
                title: string;
                subtitle: string;
                button: string;
                finishLaterButton: string;
            };
            headerTitle: string;
            headerSubtitle: string;
            button: string;
        };
        wrongBrowser: {
            title: string;
            subtitle: string;
        };
    };
    mobileSafariExtensionInstallationPagesRevamped: {
        instructionsClickSafariSettings: {
            part1: string;
            part2: string;
        };
        activateStep: {
            preamble: {
                tosConsent: string;
                startButton: string;
            };
            instructionsManageExtensions: {
                part1: string;
                part2: string;
            };
            instructionsActivateJokoExtension: string;
            headerTitle: string;
        };
        authorizeStep: {
            instructionsClickBanner: {
                part1: string;
                part2: string;
                part3: string;
            };
            instructionsClickExtensionWarningIcon: {
                part1: string;
                part2: string;
            };
            instructionsAlwaysAllow: {
                part1: string;
                part2: string;
            };
            instructionsAllowOnAllWebsites: {
                part1: string;
                part2: string;
            };
            missingBannerButton: string;
            headerTitle: string;
            headerSubtitle: string;
        };
        missingAuthorization: {
            instructionsSystemSettingsClickButton: string;
            instructionsSystemSettingsOtherWebsites: {
                part1: string;
                part2: string;
            };
            instructionsSystemSettingsAllow: {
                part1: string;
                part2: string;
            };
            systemSettingsButton: string;
            headerTitle: string;
            headerSubtitle: string;
        };
        conclusion: {
            returnToJokoButton: string;
            headerTitle: string;
            headerSubtitle: string;
        };
    };
    productDiscovery: {
        inputPlaceholder: string;
        thinkingPlaceholder: string;
        landingPage: {
            titleWithFirstName: ({ firstName }: { firstName: string }) => string;
            titleWithoutFirstName: string;
            subtitle: string;
            userMessageSuggestionsDesktop: string[];
            userMessageSuggestionsMobile: string[];
        };
        feedbackQuestion: string;
        conversationHistoryDates: {
            today: string;
            yesterday: string;
            lastMonth: string;
        };
        errorMessageOnFetchConversation: string;
    };
};
