import * as React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { useParams } from 'react-router';

import color from '../../style/color';
import { OnlineOfferActivationInfo, fetchOnlineOfferActivationInfo } from '../../api/rest/offers';
import { redirectToAffiliateLink, useAffiliateLinkUrl, useQueryParameters } from '../../lib/offers/onlineOffersActivation';

/**
 * While working on background affiliate link loading (in a hidden tab), we realized that loading the affiliate link through the
 * web app still brings additional benefits, one of them being to have `joko` being tagged as the referrer in the affiliate link.
 *
 * The `OnlineBackgroundOfferActivationPage` performs basically the same link loading mechanism as the `OnlineOfferActivationPage` but
 * without all the unnecessary UI elements and checks that are performed on the latter, since this page will be loaded in a hidden tab.
 */
function OnlineBackgroundOfferActivationPage() {
    const { userId, offerId, stackMode } = useParams<{ userId: string; offerId: string; stackMode?: string }>();
    const shouldUseDevStack = stackMode === 'dev';
    const { triggeringEventPlatform, triggeringEventType, triggeringEventTimestamp, redirectUrl, isTemporaryUser } =
        useQueryParameters();
    const [offerActivationInfo, setOfferActivationInfo] = React.useState<OnlineOfferActivationInfo | undefined>(undefined);
    React.useEffect(() => {
        fetchOnlineOfferActivationInfo(userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform).then(
            (info) => setOfferActivationInfo(info)
        );
    }, [userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform]);
    const affiliateLinkUrl = useAffiliateLinkUrl({
        userId,
        isTemporaryUser,
        shouldUseDevStack,
        offerActivationInfo,
        triggeringEventPlatform,
        triggeringEventType,
        triggeringEventTimestamp,
        isBackgroundLoading: true,
    });
    React.useEffect(() => {
        if (!offerActivationInfo || !affiliateLinkUrl) return;
        redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl });
    }, [offerActivationInfo, affiliateLinkUrl]);
    return <LoadingAnimation />;
}

export default OnlineBackgroundOfferActivationPage;

function LoadingAnimation() {
    return (
        <View style={styles.containerLoadingAnimation}>
            <ActivityIndicator color={color.frenchGray} size="large" />
        </View>
    );
}

const styles = StyleSheet.create({
    containerLoadingAnimation: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
});
