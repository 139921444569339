import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { UserProductDiscoveryConversationHistoryItem } from '../../api/graphql/fragments/productDiscovery';
import { Feature, useHasFeature } from '../../lib/features/buildFeatureMap';
import { ProductDiscoveryConversationMessageWithProducts } from '../../lib/productDiscovery/conversationMessages';
import {
    runProductDiscoveryConversationsQuery,
    useFetchMessagesOnConversationReopen,
    useProductDiscoveryConversations,
} from '../../lib/productDiscovery/conversationHistory';
import { font } from '../../style/text';
import { capitalizeFirstLetter, isMobileDevice } from '../../style/utils';
import { colors } from '../../tokens/colors/colors';
import { SpinningWheel } from '../../components/common/SpinningWheel';
import { HeaderImage, HeaderImageContainer } from './Header';
import { getLocalizedTexts } from '../../Locales';

const editIcon = '/assets/images/icons/edit.svg';
const closeConversationHistoryIcon = '/assets/images/icons/close.svg';

export function ConversationHistorySection({
    conversationId: currentConversationId,
    resetConversation,
    setMessages,
    inputTextRef,
    shouldShowConversationHistory,
    setShouldShowConversationHistory,
    selectedConversationHistoryItemId,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    receivedNewFullAssistantMessageRandomValue,
}: {
    conversationId: string;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    setMessages: (messages: ProductDiscoveryConversationMessageWithProducts[]) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
    shouldShowConversationHistory: boolean;
    setShouldShowConversationHistory: (value: boolean) => void;
    selectedConversationHistoryItemId: string | undefined;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    receivedNewFullAssistantMessageRandomValue: string | undefined;
}) {
    const history = useHistory();
    const conversationsListRef = React.useRef<HTMLDivElement | null>(null);
    const { productDiscoveryConversationsByDateMap, refetch, fetchMore, checkCanFetchMore, isFetchingMore } =
        useProductDiscoveryConversations();
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        try {
            if (!receivedNewFullAssistantMessageRandomValue || !refetch) return;
            const messagePosition = receivedNewFullAssistantMessageRandomValue.split('-')[0];
            // We refetch the conversation history to add the current conversation.
            // We wait for the first full assistant's answer before refetching the conversation history to be sure we have created the current conversation in our database.
            if (messagePosition === '1') runProductDiscoveryConversationsQuery(refetch);
        } catch (error) {
            if (isSandboxEnvironment) console.log(error);
        }
    }, [refetch, receivedNewFullAssistantMessageRandomValue]);
    useFetchMessagesOnConversationReopen({
        selectedConversationHistoryItemId,
        resetConversation,
        inputTextRef,
        setFailedToFetchConversation,
    });
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollHeight - scrollTop - clientHeight <= 10 && checkCanFetchMore()) fetchMore();
    };
    const onConversationHistoryItemClick = async (conversation: UserProductDiscoveryConversationHistoryItem) => {
        if (conversation.conversationId === selectedConversationHistoryItemId) return;
        setMessages([]);
        setFailedToFetchConversation(false);
        history.push(`/product-discovery/${conversation.conversationId}`);
        setSelectedConversationHistoryItemId(conversation.conversationId);
    };
    return (
        <ConversationHistorySectionContainer {...{ shouldShowConversationHistory, isMobileDevice }}>
            <ConversationsHistoryHeaderContainer {...{ isMobileDevice }}>
                {shouldShowConversationHistory ? (
                    <>
                        <HeaderImageContainer
                            onClick={() => {
                                resetConversation({});
                                if (isMobileDevice) setShouldShowConversationHistory(false);
                                inputTextRef.current?.focus();
                            }}>
                            <HeaderImage src={editIcon} />
                        </HeaderImageContainer>
                        <HeaderImageContainer
                            onClick={() => setShouldShowConversationHistory(!shouldShowConversationHistory)}>
                            <HeaderImage src={closeConversationHistoryIcon} />
                        </HeaderImageContainer>
                    </>
                ) : null}
            </ConversationsHistoryHeaderContainer>
            {productDiscoveryConversationsByDateMap && Object.keys(productDiscoveryConversationsByDateMap).length > 0 ? (
                <ConversationsHistoryContainer {...{ isMobileDevice }} ref={conversationsListRef} onScroll={handleScroll}>
                    {Object.entries(productDiscoveryConversationsByDateMap).map(
                        ([date, productDiscoveryConversations], index) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={date}>
                                    <ConversationsHistoryTextContainer>
                                        <ConversationsHistoryText style={{ fontFamily: font.ambitBold, marginBottom: 8 }}>
                                            {date}
                                        </ConversationsHistoryText>
                                    </ConversationsHistoryTextContainer>
                                    {productDiscoveryConversations.map((conversation) => {
                                        return (
                                            <ConversationsHistoryTextContainer
                                                {...{
                                                    shouldAddHoverEffect: true,
                                                    isSelected:
                                                        conversation.conversationId === selectedConversationHistoryItemId ||
                                                        conversation.conversationId === currentConversationId,
                                                }}
                                                key={conversation.conversationId}
                                                onClick={() => onConversationHistoryItemClick(conversation)}>
                                                <ConversationsHistoryText>
                                                    {capitalizeFirstLetter(conversation.conversationNameToDisplay)}
                                                </ConversationsHistoryText>
                                            </ConversationsHistoryTextContainer>
                                        );
                                    })}
                                    {index === Object.keys(productDiscoveryConversationsByDateMap).length - 1 ? (
                                        isFetchingMore ? (
                                            <div style={{ marginTop: 16, marginBottom: 16 }}>
                                                <SpinningWheel {...{ width: 18, height: 18 }} />
                                            </div>
                                        ) : (
                                            <div style={{ height: 32 }} />
                                        )
                                    ) : (
                                        <ConversationsHistorySeparator {...{ isMobileDevice }} />
                                    )}
                                </div>
                            );
                        }
                    )}
                </ConversationsHistoryContainer>
            ) : null}
        </ConversationHistorySectionContainer>
    );
}

export function ErrorMessageOnFetchConversation() {
    const texts = getLocalizedTexts().productDiscovery;
    return (
        <ErrorMessageContainer>
            <ErrorMessageText>{texts.errorMessageOnFetchConversation}</ErrorMessageText>
        </ErrorMessageContainer>
    );
}

const ConversationHistorySectionContainer = styled.div<{
    shouldShowConversationHistory: boolean;
    isMobileDevice: boolean;
}>`
    display: flex;
    position: ${({ isMobileDevice }) => (isMobileDevice ? 'absolute' : 'relative')};
    z-index: 1;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: center;
    width: ${({ shouldShowConversationHistory }) =>
        shouldShowConversationHistory ? (isMobileDevice ? '278px' : '248px') : '0px'};
    height: 100%;
    transition: width 0.3s;
    overflow: hidden; /* Prevent content overflow when width is reduced */
    background-color: ${colors.background.light};
`;

const ConversationsHistoryHeaderContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    width: ${({ isMobileDevice }) => (isMobileDevice ? 'calc(100% - 32px)' : 'calc(100% - 48px)')};
    min-height: ${({ isMobileDevice }) => (isMobileDevice ? '52px' : '48px')};
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
`;

const ConversationsHistoryContainer = styled.div<{ isMobileDevice: boolean }>`
    display: flex;
    flex-direction: column;
    width: calc(100% - 24px);
    padding: 0px 12px;
    margin-top: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '24px')};
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: ${colors.border.secondary} ${colors.background.light};
`;

const ConversationsHistoryTextContainer = styled.div<{ shouldAddHoverEffect?: boolean; isSelected?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: ${({ isSelected }) => (isSelected ? colors.background.subtle : '')};

    ${({ shouldAddHoverEffect }) =>
        shouldAddHoverEffect
            ? `
        &:hover {
            background-color: ${colors.background.subtle};
            cursor: pointer;
        }
    `
            : ''}
`;

const ConversationsHistorySeparator = styled.div<{ isMobileDevice: boolean }>`
    width: 100%;
    height: 1px;
    margin-top: ${({ isMobileDevice }) => (isMobileDevice ? '8px' : '24px')};
    margin-bottom: ${({ isMobileDevice }) => (isMobileDevice ? '16px' : '32px')};
    background-color: ${colors.border.secondary};
`;

const ConversationsHistoryText = styled.div`
    display: -webkit-box;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const ErrorMessageContainer = styled.div`
    display: flex;
    width: 50%;
    align-self: center;
    margin-top: 32px;
`;

const ErrorMessageText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    text-align: center;
    color: ${colors.content.secondary};
`;
