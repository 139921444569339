import React from 'react';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';

import { useApolloClient } from '../../api/config';
import { ApolloClient } from '../../api/graphql/client';
import { Feature, useHasFeature } from '../features/buildFeatureMap';
import { getMerchantName } from '../merchants/merchants';
import { getWebSocketApiUrl } from './websockets';
import { getLocaleCode } from '../../Locales';
import { updateProductDiscoveryConversationMessageWithProductsListInCache } from './conversationHistory';

type ProductDiscoveryConversationMessage = {
    messagePosition: string;
    content: string;
    role: 'system' | 'user' | 'assistant';
    timestamp: number;
};
export interface MerchantProductOffer {
    merchantId: string | null;
    merchantProductOfferId: string | null;
    originalTitle: string;
    processedWebpageUrl: string | null;
    mainImageUrls: {
        defaultSize: string;
    };
    priceInformation: {
        displayPriceAmount: {
            currency: string;
            valueInCents: number;
        };
    };
    contextualInformation: {
        originalMerchantName: string | null;
    };
}

export type ProductDiscoveryConversationMessageWithProducts = ProductDiscoveryConversationMessage & {
    merchantProductOffers?: MerchantProductOffer[];
};

interface AssistantStreamResponse {
    chunk: string;
}

type GetProductDiscoveryConversationIdPayload = {
    action: 'getProductDiscoveryConversationId';
    userId: string | undefined;
};

type CloseProductDiscoveryConversationPayload = {
    action: 'closeProductDiscoveryConversation';
    userId: string | undefined;
    conversationId: string;
};

type StreamProductDiscoveryMessagePayload = {
    action: 'streamProductDiscoveryMessage';
    userId: string | undefined;
    timestamp: number;
    conversationId: string;
    message: string;
    localeCode: string;
    shouldLogAllData: boolean;
};

type WebSocketMessagePayload =
    | GetProductDiscoveryConversationIdPayload
    | CloseProductDiscoveryConversationPayload
    | StreamProductDiscoveryMessagePayload;

function sendWebSocketMessage({ webSocket, payload }: { webSocket: WebSocket; payload: WebSocketMessagePayload }) {
    if (webSocket.readyState === WebSocket.OPEN) webSocket.send(JSON.stringify(payload));
}

export function useHandleConnectionWithAssistant({
    userId,
    webSocketRef,
    messageCounterRef,
    setIsWebSocketConnectionOpen,
    messages,
    setMessages,
    setIsAssistantThinking,
    conversationId,
    setConversationId,
    receivedNewFullAssistantMessageRandomValue,
    setReceivedNewFullAssistantMessageRandomValue,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    conversationId: string;
    setConversationId: (value: string) => void;
    receivedNewFullAssistantMessageRandomValue: string | undefined;
    setReceivedNewFullAssistantMessageRandomValue: (value: string | undefined) => void;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const currentAssistantMessageRef = React.useRef<ProductDiscoveryConversationMessageWithProducts | undefined>(undefined);
    useCloseWebSocketOnUnmount({ userId, conversationId, webSocketRef });
    useUpdateMessagesInCache({ conversationId, messages, receivedNewFullAssistantMessageRandomValue });
    /**
     * If the web socket is not connected when the user tries to send a message for some reason,
     * we initialize it again and rely on the `payloadToSendOnWebSocketOpen` to send the message right after the connection is open.
     */
    function initializeWebSocket({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) {
        if (!userId) return;
        if (isSandboxEnvironment) console.log('Initializing WebSocket connection...');
        const websocketUrl = getWebSocketApiUrl();
        const webSocket = new WebSocket(websocketUrl + '?userId=' + userId);
        webSocketRef.current = webSocket;
        webSocket.onopen = () =>
            handleWebSocketOpen({
                webSocket,
                setIsWebSocketConnectionOpen,
                shouldGetNewConversationId,
                payloadToSendOnWebSocketOpen,
                userId,
                isSandboxEnvironment,
            });
        webSocket.onmessage = (event: MessageEvent) =>
            handleWebSocketMessage({
                event,
                isSandboxEnvironment,
                setConversationId,
                currentAssistantMessageRef,
                messageCounterRef,
                setMessages,
                setIsAssistantThinking,
                setReceivedNewFullAssistantMessageRandomValue,
            });
        webSocket.onerror = (error) => {
            if (isSandboxEnvironment) console.error('WebSocket error:', error);
        };
        webSocket.onclose = (event: CloseEvent) => handleWebSocketClose({ event, isSandboxEnvironment });
    }
    function closeWebSocket({ conversationIdToClose }: { conversationIdToClose?: string }) {
        if (webSocketRef.current) {
            if (conversationIdToClose) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId: conversationIdToClose,
                    },
                });
                setConversationId('');
            }
            // We add a 1000 code to the close method to indicate a normal closure
            webSocketRef.current.close(1000);
        }
    }
    const { conversationId: conversationIdParam } = useParams<{ conversationId?: string }>();
    React.useEffect(() => {
        if (!userId || conversationIdParam) return;
        initializeWebSocket({ shouldGetNewConversationId: true });
    }, [userId]);
    return { initializeWebSocket, closeWebSocket };
}

function useCloseWebSocketOnUnmount({
    userId,
    conversationId,
    webSocketRef,
}: {
    userId: string | undefined;
    conversationId: string;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
}) {
    React.useEffect(() => {
        if (!userId || !conversationId) return;
        const handleCloseConversationBeforeUnload = () => {
            if (webSocketRef.current?.readyState === WebSocket.OPEN) {
                sendWebSocketMessage({
                    webSocket: webSocketRef.current,
                    payload: {
                        action: 'closeProductDiscoveryConversation',
                        userId,
                        conversationId,
                    },
                });
                // We add a 1000 code to the close method to indicate a normal closure
                webSocketRef.current.close(1000);
            }
        };
        window.addEventListener('beforeunload', () => handleCloseConversationBeforeUnload());
    }, [userId, conversationId]);
}

function handleWebSocketOpen({
    webSocket,
    setIsWebSocketConnectionOpen,
    shouldGetNewConversationId,
    payloadToSendOnWebSocketOpen,
    userId,
    isSandboxEnvironment,
}: {
    webSocket: WebSocket;
    setIsWebSocketConnectionOpen: (value: boolean) => void;
    shouldGetNewConversationId?: boolean;
    payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    userId: string;
    isSandboxEnvironment: boolean;
}) {
    setIsWebSocketConnectionOpen(true);
    if (payloadToSendOnWebSocketOpen) {
        // If we have a `payloadToSendOnWebSocketOpen` it means we want to continue the conversation with the same `conversationId`
        sendWebSocketMessage({ webSocket, payload: payloadToSendOnWebSocketOpen });
        return;
    }
    if (shouldGetNewConversationId) {
        if (isSandboxEnvironment) console.log('Fetching a new conversation ID...');
        sendWebSocketMessage({
            webSocket,
            payload: {
                action: 'getProductDiscoveryConversationId',
                userId,
            },
        });
    }
}

function handleWebSocketMessage({
    event,
    isSandboxEnvironment,
    setConversationId,
    currentAssistantMessageRef,
    messageCounterRef,
    setMessages,
    setIsAssistantThinking,
    setReceivedNewFullAssistantMessageRandomValue,
}: {
    event: MessageEvent;
    isSandboxEnvironment: boolean;
    setConversationId: (value: string) => void;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    setReceivedNewFullAssistantMessageRandomValue: (value: string | undefined) => void;
}) {
    const data = JSON.parse(event.data);
    if (data.productDiscoveryConversationId) {
        if (isSandboxEnvironment)
            console.log('Received product discovery conversation ID:', data.productDiscoveryConversationId);
        setConversationId(data.productDiscoveryConversationId);
    } else if ((data.content && data.role === 'assistant') || isAssistantStreamResponse(data))
        handleAssistantMessage({
            response: data,
            isSandboxEnvironment,
            currentAssistantMessageRef,
            messageCounterRef,
            setMessages,
            setIsAssistantThinking,
            setReceivedNewFullAssistantMessageRandomValue,
        });
    else if (isSandboxEnvironment) console.warn('Unknown message format:', JSON.stringify(data));
}

function handleWebSocketClose({ event, isSandboxEnvironment }: { event: CloseEvent; isSandboxEnvironment: boolean }) {
    if (isSandboxEnvironment) {
        if (event.code === 1000) console.log(`WebSocket closed with code: ${event.code}`);
        else console.error(`WebSocket closed with code: ${event.code}`);
    }
}

/**
 * The API sends back either chunks of a message when sending back the streamed assistant response,
 * or the full message when the assistant has finished processing the user's message. The full message also
 * contains the merchant product offers, that we use to display the products within the conversation.
 */
function handleAssistantMessage({
    response,
    isSandboxEnvironment,
    currentAssistantMessageRef,
    messageCounterRef,
    setMessages,
    setIsAssistantThinking,
    setReceivedNewFullAssistantMessageRandomValue,
}: {
    response: ProductDiscoveryConversationMessageWithProducts | AssistantStreamResponse;
    isSandboxEnvironment: boolean;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    setReceivedNewFullAssistantMessageRandomValue: (value: string | undefined) => void;
}) {
    if (isAssistantStreamResponse(response))
        handleStreamAssistantMessage({
            response,
            currentAssistantMessageRef,
            messageCounterRef,
            setMessages,
        });
    else if (response.content && response.role === 'assistant')
        handleFullAssistantMessage({
            response,
            isSandboxEnvironment,
            currentAssistantMessageRef,
            setMessages,
            setIsAssistantThinking,
            messageCounterRef,
            setReceivedNewFullAssistantMessageRandomValue,
        });
    else if (isSandboxEnvironment) console.warn('Received assistant content but no current assistant message:', response);
}

function isAssistantStreamResponse(response: any): response is AssistantStreamResponse {
    return _.has(response, 'chunk');
}

function handleStreamAssistantMessage({
    response,
    currentAssistantMessageRef,
    messageCounterRef,
    setMessages,
}: {
    response: AssistantStreamResponse;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
}) {
    if (!response.chunk) return;
    if (currentAssistantMessageRef.current === undefined) {
        const assistantMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: messageCounterRef.current.toString(),
            timestamp: Date.now(),
            content: response.chunk,
            role: 'assistant',
        };
        currentAssistantMessageRef.current = assistantMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messagePosition === currentAssistantMessageRef.current?.messagePosition ? assistantMessage : message
            )
        );
    } else {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: (currentAssistantMessageRef.current.content || '') + response.chunk,
        };
        currentAssistantMessageRef.current = updatedMessage;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messagePosition === currentAssistantMessageRef.current?.messagePosition ? updatedMessage : message
            )
        );
    }
}

function handleFullAssistantMessage({
    response,
    isSandboxEnvironment,
    currentAssistantMessageRef,
    setMessages,
    setIsAssistantThinking,
    messageCounterRef,
    setReceivedNewFullAssistantMessageRandomValue,
}: {
    response: ProductDiscoveryConversationMessageWithProducts;
    isSandboxEnvironment: boolean;
    currentAssistantMessageRef: React.MutableRefObject<ProductDiscoveryConversationMessageWithProducts | undefined>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    setIsAssistantThinking: (value: boolean) => void;
    messageCounterRef: React.MutableRefObject<number>;
    setReceivedNewFullAssistantMessageRandomValue: (value: string | undefined) => void;
}) {
    if (currentAssistantMessageRef.current) {
        const updatedMessage = {
            ...currentAssistantMessageRef.current,
            content: response.content || currentAssistantMessageRef.current.content || '',
            merchantProductOffers: response.merchantProductOffers || [],
        };
        currentAssistantMessageRef.current = undefined;
        setMessages((prevMessages) =>
            prevMessages?.map((message) =>
                message.messagePosition === updatedMessage.messagePosition ? updatedMessage : message
            )
        );
        setIsAssistantThinking(false);
        // We encode the message position in the random value so that we can use it in the conversation history section.
        // We use a random value to force a re-render of the conversation history list when the assistant has finished processing the user's message.
        setReceivedNewFullAssistantMessageRandomValue(`${messageCounterRef.current}-${Math.random()}`);
    } else if (isSandboxEnvironment) console.warn('Received assistant content but no current assistant message:', response);
}

export function useSendMessage({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageCounterRef,
    setMessages,
    conversationId,
    inputText,
    setInputText,
    initializeWebSocket,
    messagesListRef,
    setIsAssistantThinking,
    firstMessageToSend,
    setFirstMessageToSend,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    conversationId: string;
    inputText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    firstMessageToSend: string | undefined;
    setFirstMessageToSend: (value: string) => void;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const localeCode = React.useMemo(() => getLocaleCode(), []);
    useSendMessageOnConversationInit({
        userId,
        webSocketRef,
        messageCounterRef,
        isWebSocketConnectionOpen,
        setMessages,
        conversationId,
        firstMessageToSend,
        localeCode,
        isSandboxEnvironment,
        setInputText,
        setIsAssistantThinking,
    });
    const sendMessage = (suggestedMessage?: string | undefined) => {
        const messageToSend = suggestedMessage ? suggestedMessage : inputText;
        if (messageToSend.trim().length === 0) return;
        if (!conversationId || !webSocketRef.current || !isWebSocketConnectionOpen) {
            // If the `conversationId` is not created yet, we store the message and send it as soon as the connection is open
            setFirstMessageToSend(messageToSend);
            return;
        }
        const userMessagePosition = messageCounterRef.current === 0 ? '0' : generateUniqueMessagePosition(messageCounterRef);
        const payload: WebSocketMessagePayload = {
            action: 'streamProductDiscoveryMessage',
            userId,
            timestamp: Date.now(),
            conversationId,
            message: messageToSend,
            localeCode,
            shouldLogAllData: true,
        };
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: userMessagePosition,
            timestamp: Date.now(),
            content: messageToSend,
            role: 'user',
        };
        const assistantMessagePosition = generateUniqueMessagePosition(messageCounterRef);
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messagePosition: assistantMessagePosition,
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        // If the web socket is not connected when the user tries to send a message, we force a reconnection and send the message right after
        if (webSocketRef.current?.readyState !== WebSocket.OPEN) {
            if (isSandboxEnvironment) console.log('WebSocket connection is not open. Reconnecting...');
            initializeWebSocket({ shouldGetNewConversationId: false, payloadToSendOnWebSocketOpen: payload });
        } else sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
        setInputText('');
        setIsAssistantThinking(true);
        setTimeout(
            () => messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' }),
            100
        ); // We scroll to the end of the list after a delay to ensure the message is rendered
    };
    return sendMessage;
}

function useUpdateMessagesInCache({
    conversationId,
    messages,
    receivedNewFullAssistantMessageRandomValue,
}: {
    conversationId: string | undefined;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    receivedNewFullAssistantMessageRandomValue: string | undefined;
}) {
    const apolloClient = useApolloClient();
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        if (
            !apolloClient ||
            !conversationId ||
            !messages?.length ||
            receivedNewFullAssistantMessageRandomValue === undefined
        )
            return;
        updateProductDiscoveryConversationMessageWithProductsListInCache(
            apolloClient,
            conversationId,
            isSandboxEnvironment,
            messages.map((message) => ({
                ...message,
                __typename: 'UserProductDiscoveryMessageWithProducts',
                messageId: `${conversationId}|${message.messagePosition}`,
                merchantProductOffers: message.merchantProductOffers
                    ? message.merchantProductOffers.map((offer) => {
                          return {
                              __typename: 'MerchantProductOffer',
                              ...offer,
                          };
                      })
                    : [],
            }))
        );
    }, [apolloClient, conversationId, receivedNewFullAssistantMessageRandomValue]);
}

/**
 * If the first message has been entered by the user before the connection was initialized, we send it as soon the connection is open.
 * To do so, this hook relies on the `firstMessageToSend` and the `conversationId` states:
 * - If `conversationId` is not yet initialized when the first message was entered, the message is saved in `firstMessageToSend`,
 *   allowing this effect to fake the sending of the message, waiting for the conversation ID to be created.
 * - When `conversationId` is initialized, this effect re-renders and sends the first message immediately.
 */
function useSendMessageOnConversationInit({
    userId,
    webSocketRef,
    isWebSocketConnectionOpen,
    messageCounterRef,
    setMessages,
    conversationId,
    firstMessageToSend,
    localeCode,
    isSandboxEnvironment,
    setInputText,
    setIsAssistantThinking,
}: {
    userId: string | undefined;
    webSocketRef: React.MutableRefObject<WebSocket | undefined>;
    isWebSocketConnectionOpen: boolean;
    messageCounterRef: React.MutableRefObject<number>;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    conversationId: string;
    firstMessageToSend: string | undefined;
    localeCode: string;
    isSandboxEnvironment: boolean;
    setInputText: (value: string) => void;
    setIsAssistantThinking: (value: boolean) => void;
}) {
    React.useEffect(() => {
        if (!firstMessageToSend) return;
        if (conversationId && webSocketRef.current && isWebSocketConnectionOpen) {
            // As soon as the `conversationId` is initialized, we send the first message
            const payload: WebSocketMessagePayload = {
                action: 'streamProductDiscoveryMessage',
                userId,
                timestamp: Date.now(),
                conversationId,
                message: firstMessageToSend,
                localeCode,
                shouldLogAllData: true,
            };
            generateUniqueMessagePosition(messageCounterRef);
            if (isSandboxEnvironment) console.log('Sending the first message after connection was initialized');
            sendWebSocketMessage({ webSocket: webSocketRef.current, payload });
            return;
        }
        if (isSandboxEnvironment) console.log('The first message has been entered before the connection was initialized');
        const userMessage: ProductDiscoveryConversationMessageWithProducts = {
            messagePosition: messageCounterRef.current.toString(),
            timestamp: Date.now(),
            content: firstMessageToSend,
            role: 'user',
        };
        setMessages((prevMessages) => [
            ...(prevMessages || []),
            userMessage,
            {
                messagePosition: (messageCounterRef.current + 1).toString(),
                content: '',
                role: 'assistant',
                timestamp: Date.now(),
            },
        ]);
        setInputText('');
        setIsAssistantThinking(true);
    }, [conversationId, firstMessageToSend, isWebSocketConnectionOpen]);
}

function generateUniqueMessagePosition(messageCounterRef: React.MutableRefObject<number>) {
    messageCounterRef.current += 1;
    return messageCounterRef.current.toString();
}

export function useResetConversation({
    conversationId,
    setMessages,
    messageCounterRef,
    messagesListRef,
    setIsAssistantThinking,
    setFirstMessageToSend,
    setConversationId,
    initializeWebSocket,
    closeWebSocket,
    setShouldShowScrollToBottomButton,
    setUserFeedbackMap,
    setSelectedConversationHistoryItemId,
    setFailedToFetchConversation,
    setInputText,
}: {
    conversationId: string;
    setMessages: React.Dispatch<React.SetStateAction<ProductDiscoveryConversationMessageWithProducts[] | undefined>>;
    messageCounterRef: React.MutableRefObject<number>;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    setIsAssistantThinking: (value: boolean) => void;
    setFirstMessageToSend: (value: string | undefined) => void;
    setConversationId: (value: string) => void;
    initializeWebSocket: ({
        shouldGetNewConversationId,
        payloadToSendOnWebSocketOpen,
    }: {
        shouldGetNewConversationId?: boolean;
        payloadToSendOnWebSocketOpen?: WebSocketMessagePayload;
    }) => void;
    closeWebSocket: ({ conversationIdToClose }: { conversationIdToClose?: string }) => void;
    setShouldShowScrollToBottomButton: (value: boolean) => void;
    setUserFeedbackMap: (value: { [id: string]: 'positive' | 'negative' | null }) => void;
    setSelectedConversationHistoryItemId: (value: string | undefined) => void;
    setFailedToFetchConversation: (value: boolean) => void;
    setInputText: (value: string) => void;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    const history = useHistory();
    return React.useCallback(
        ({
            conversationToReopen,
        }: {
            conversationToReopen?: {
                conversationId: string;
                messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
            };
        }) => {
            if (isSandboxEnvironment)
                console.log(
                    `Resetting conversation:\n conversationIdToClose: ${conversationId} \n conversationIdToReopen: ${conversationToReopen?.conversationId}`
                );
            // We close the web socket connection so that we can open a new one with a new conversation ID
            closeWebSocket({ conversationIdToClose: conversationId });
            if (conversationToReopen && conversationToReopen.messages) {
                setConversationId(conversationToReopen.conversationId);
                setMessages(conversationToReopen.messages);
                handleScrollToBottom(messagesListRef, 'instant');
                messageCounterRef.current = conversationToReopen.messages.length - 1;
                initializeWebSocket({ shouldGetNewConversationId: false });
            } else {
                setMessages(undefined);
                history.push('/product-discovery');
                setSelectedConversationHistoryItemId(undefined);
                messageCounterRef.current = 0;
                initializeWebSocket({ shouldGetNewConversationId: true });
            }
            setFailedToFetchConversation(false);
            setIsAssistantThinking(false);
            setFirstMessageToSend(undefined);
            setShouldShowScrollToBottomButton(false);
            setUserFeedbackMap({});
            setInputText('');
        },
        [conversationId]
    );
}

export type MerchantIdToMerchantNameMap = {
    [merchantId: string]: string | undefined;
};

export function useMerchantIdToMerchantNameMap({
    apolloClient,
    messages,
}: {
    apolloClient: ApolloClient | undefined;
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
}) {
    const [merchantIdToMerchantNameMap, setMerchantIdToMerchantNameMap] = React.useState<MerchantIdToMerchantNameMap>({});
    React.useEffect(() => {
        if (!apolloClient || !messages?.length) return;
        const lastSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!lastSuggestedMerchantProductOffers) return;
        const merchantIdsToFetch = lastSuggestedMerchantProductOffers
            .map((offer) => offer.merchantId)
            .filter(
                (merchantId) => !!merchantId && !merchantIdToMerchantNameMap[merchantId] // We only fetch a merchant name if it doesn't already exist in the map
            );
        const fetchMerchantNames = async () => {
            const merchantNames = await Promise.all(
                merchantIdsToFetch.map((merchantId) => getMerchantName(apolloClient, merchantId as string))
            );
            const newMerchantIdToMerchantNameMap = merchantNames.reduce(
                (newMap: { [merchantId: string]: string | undefined }, { merchantId, merchantName }) => {
                    newMap[merchantId] = merchantName;
                    return newMap;
                },
                {}
            );
            setMerchantIdToMerchantNameMap({ ...merchantIdToMerchantNameMap, ...newMerchantIdToMerchantNameMap });
        };
        fetchMerchantNames();
    }, [messages]);
    return merchantIdToMerchantNameMap;
}

export function useScrollToBottom({
    messages,
    messagesListRef,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
    const [shouldShowScrollToBottomButton, setShouldShowScrollToBottomButton] = React.useState(false);
    React.useEffect(() => {
        if (messagesListRef.current && messagesListRef.current.scrollHeight > messagesListRef.current.clientHeight)
            setShouldShowScrollToBottomButton(true);
    }, [messages]);
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const currentPosition = scrollTop + clientHeight;
        // We show the scroll-to-bottom button only if the user is not at the bottom
        if (currentPosition < scrollHeight - 10) setShouldShowScrollToBottomButton(true);
        else setShouldShowScrollToBottomButton(false);
    };
    return { shouldShowScrollToBottomButton, setShouldShowScrollToBottomButton, handleScroll };
}

export function handleScrollToBottom(
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>,
    behavior: ScrollBehavior
) {
    messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior });
}

export function useSuggestedProductsDebugLogs({
    messages,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[] | undefined;
}) {
    const isSandboxEnvironment = !!useHasFeature(Feature.useProductDiscoverySandboxEnvironment);
    React.useEffect(() => {
        if (!isSandboxEnvironment || !messages?.length) return;
        const newSuggestedMerchantProductOffers = messages[messages.length - 1].merchantProductOffers;
        if (!newSuggestedMerchantProductOffers) return;
        console.log('Suggested MPOs:', JSON.stringify(newSuggestedMerchantProductOffers, null, 2));
    }, [messages, isSandboxEnvironment]);
}
