import * as React from 'react';
import { StyleSheet, Text, View, ImageSourcePropType, Image, Modal, TouchableOpacity } from 'react-native';
import { useHistory } from 'react-router';

import { getLocalizedTexts } from '../../../Locales';
import { font } from '../../../style/text';
import color from '../../../style/color';
import { logAmplitudeEvent } from '../../../lib/events/amplitudeEvents';
import { useUserFeaturesMap } from '../../../lib/features/buildFeatureMap';
import { accessHelpCenter } from '../../../lib/common/intercom';
import { useDeleteUser, useSignOut } from '../../../lib/common/user';
import { MarketingWebsitePath, getMarketingWebsiteUrl } from '../../../lib/auth/region';
import { HoverableTouchableOpacity } from '../../../components/common/HoverableComponents';

const profileIcon = { uri: '/assets/images/icons/profile-blue.svg' };
const bookIcon = { uri: '/assets/images/icons/book-green.svg' };
const lockIcon = { uri: '/assets/images/icons/lock-yellow.svg' };
const exitIcon = { uri: '/assets/images/icons/exit-blue.svg' };
const binIcon = { uri: '/assets/images/icons/bin.svg' };
const shareIcon = { uri: '/assets/images/icons/share-gray.svg' };
const helpIcon = { uri: '/assets/images/icons/help-black.svg' };

function ProfilePanel() {
    const texts = getLocalizedTexts().home.profile.menu;
    const history = useHistory();
    const userFeaturesMap = useUserFeaturesMap();
    const [signOut] = useSignOut();
    const deleteUser = useDeleteUser();
    const [dialogState, setDialogState] = React.useState<DialogState>({ isVisible: false });
    const closeDialog = () => setDialogState({ ...dialogState, isVisible: false });
    return (
        <View style={styles.container}>
            <View style={styles.containerMenu}>
                <Text style={styles.textSectionTitle}>{texts.settingsSection.title.toUpperCase()}</Text>
                <MenuItem
                    title={texts.settingsSection.userInfo}
                    image={profileIcon}
                    onPress={() => history.push('/home/profile/user-info')}
                    isSelected
                />
                <MenuItem
                    title={texts.settingsSection.termsOfService}
                    image={bookIcon}
                    onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.termOfService }))}
                    showShareIcon
                />
                <MenuItem
                    title={texts.settingsSection.privacyPolicy}
                    image={lockIcon}
                    onPress={() => window.open(getMarketingWebsiteUrl({ path: MarketingWebsitePath.privacyPolicy }))}
                    showShareIcon
                />
                <MenuItem
                    title={texts.settingsSection.signOut}
                    image={exitIcon}
                    onPress={() => {
                        signOut();
                        logAmplitudeEvent({ name: 'Settings - Clicked Logout' });
                    }}
                />
                <MenuItem
                    title={texts.settingsSection.deleteUser}
                    image={binIcon}
                    onPress={() => {
                        const dialogState = {
                            isVisible: true as true,
                            title: texts.deleteUserDialog.title,
                            subtitle: texts.deleteUserDialog.subtitle,
                            primaryButtonText: texts.deleteUserDialog.buttons.no,
                            secondaryButtonText: texts.deleteUserDialog.buttons.yes,
                            onPrimaryButtonPress: closeDialog,
                            onSecondaryButtonPress: () => {
                                setDialogState({
                                    ...dialogState,
                                    isLoading: true,
                                    loadingMessage: texts.deleteUserDialog.deletionInProgress,
                                });
                                deleteUser();
                                logAmplitudeEvent({ name: 'Settings - Clicked Delete Account' });
                            },
                            isLoading: false as false,
                        };
                        setDialogState({ ...dialogState });
                    }}
                />
                <Dialog {...{ dialogState, closeDialog }} />
            </View>
            <View style={styles.containerMenu}>
                <Text style={styles.textSectionTitle}>{texts.supportSection.title.toUpperCase()}</Text>
                <MenuItem
                    title={texts.supportSection.faq}
                    image={helpIcon}
                    onPress={() => {
                        logAmplitudeEvent({ name: 'Profile - Clicked FAQ' });
                        accessHelpCenter(userFeaturesMap);
                    }}
                    showShareIcon
                />
            </View>
        </View>
    );
}

export default ProfilePanel;

function MenuItem({
    title,
    image,
    onPress,
    isSelected,
    showShareIcon,
}: {
    title: string;
    image: ImageSourcePropType;
    onPress: () => void;
    isSelected?: boolean;
    showShareIcon?: boolean;
}) {
    return (
        <HoverableTouchableOpacity
            style={[styles.containerMenuItem, isSelected && styles.containerMenuItemSelected]}
            hoveredStyle={styles.containerMenuItemHovered}
            onPress={onPress}>
            <Image source={image} style={styles.imageMenuItemIcon} />
            <Text style={styles.textMenuItem}>{title}</Text>
            {showShareIcon ? <Image source={shareIcon} style={styles.imageShareIcon} /> : null}
        </HoverableTouchableOpacity>
    );
}

export type DialogState =
    | {
          isVisible: false;
      }
    | ({
          isVisible: true;
          title: string;
          subtitle: string;
          primaryButtonText: string;
          secondaryButtonText: string;
          onPrimaryButtonPress: () => void;
          onSecondaryButtonPress: () => void;
      } & ({ isLoading: false } | { isLoading: true; loadingMessage: string }));

export function Dialog({ dialogState }: { dialogState: DialogState }) {
    return (
        <Modal visible={dialogState.isVisible} transparent>
            <View style={styles.containerDialogBackground}>
                {dialogState.isVisible ? (
                    !dialogState.isLoading ? (
                        <View style={styles.containerDialog}>
                            {dialogState.title ? <Text style={styles.textDialogTitle}>{dialogState.title}</Text> : null}
                            <Text style={styles.textDialogSubtitle}>{dialogState.subtitle}</Text>
                            <TouchableOpacity
                                style={styles.containerDialogPrimaryButton}
                                onPress={() => dialogState.onPrimaryButtonPress()}>
                                <Text style={styles.textDialogPrimaryButton}>{dialogState.primaryButtonText}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.containerDialogSecondaryButton}
                                onPress={() => dialogState.onSecondaryButtonPress()}>
                                <Text style={styles.textDialogSecondaryButton}>{dialogState.secondaryButtonText}</Text>
                            </TouchableOpacity>
                        </View>
                    ) : (
                        <View style={styles.containerDialog}>
                            <Text style={styles.textDialogTitle}>{dialogState.loadingMessage}</Text>
                        </View>
                    )
                ) : null}
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: color.athensGray,
        paddingTop: 16,
        paddingHorizontal: 71,
        alignItems: 'flex-end',
    },
    containerMenu: {
        marginTop: 32,
        width: 345,
    },
    containerMenuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 13,
        paddingHorizontal: 19,
        borderRadius: 8,
    },
    containerMenuItemHovered: {
        backgroundColor: color.concrete,
    },
    containerMenuItemSelected: {
        backgroundColor: color.athensGrayDark,
    },
    containerDialogBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    containerDialog: {
        width: 540,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: color.white,
        paddingHorizontal: 40,
        paddingBottom: 40,
        borderRadius: 10,
    },
    containerDialogPrimaryButton: {
        paddingVertical: 14,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 60,
        backgroundColor: color.black,
        borderWidth: 2,
        borderColor: color.black,
    },
    containerDialogSecondaryButton: {
        marginTop: 15,
        paddingVertical: 14,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 60,
        backgroundColor: color.white,
        borderWidth: 2,
        borderColor: color.black,
    },
    textSectionTitle: {
        fontSize: 12,
        fontFamily: font.ambitBlack,
        color: color.frenchGray,
        marginHorizontal: 19,
        marginBottom: 16,
    },
    textMenuItem: {
        flex: 1,
        marginLeft: 20,
        fontSize: 14,
        fontFamily: font.ambitBlack,
        color: color.black,
    },
    textDialogTitle: {
        marginTop: 40,
        marginHorizontal: 25,
        fontFamily: font.ambitBold,
        fontSize: 24,
        color: color.black,
        textAlign: 'center',
    },
    textDialogSubtitle: {
        marginVertical: 30,
        marginHorizontal: 15,
        fontFamily: font.ambitSemiBold,
        fontSize: 16,
        color: color.black,
        textAlign: 'center',
    },
    textDialogPrimaryButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.white,
    },
    textDialogSecondaryButton: {
        fontFamily: font.ambitBold,
        fontSize: 14,
        color: color.black,
    },
    imageMenuItemIcon: {
        width: 24,
        height: 24,
        resizeMode: 'contain',
    },
    imageShareIcon: {
        width: 16,
        height: 16,
        resizeMode: 'contain',
    },
});
