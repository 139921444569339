export enum SettingKey {
    numberOngoingOfferRetroactiveDays = 'numberOngoingOfferRetroactiveDays',
    offerRecommendationModalTimeIntervalDays = 'offerRecommendationModalTimeIntervalDays',
    askingForTrackingConsentTimeIntervalMonths = 'askingForTrackingConsentTimeIntervalMonths',
    preRegistrationTwoGroupAbTestSettings = 'preRegistrationTwoGroupAbTestSettings',
    adBlockerFaqLink = 'adBlockerFaqLink',
    shouldUseIntercomWhenLoggedOutOnWebApp = 'shouldUseIntercomWhenLoggedOutOnWebApp',
    productDiscoveryUserMessageSuggestions = 'productDiscoveryUserMessageSuggestions',
}

export interface Setting {
    __typename?: 'Setting';
    settingKey: SettingKey;
    value: string;
}

export const dataIdFromSetting = (setting: Setting) => {
    return `Setting:${setting.settingKey}`;
};
