import * as React from 'react';
import {
    Modal,
    StyleSheet,
    View,
    Text,
    Image,
    TouchableOpacity,
    Dimensions,
    Platform,
    ActivityIndicator,
} from 'react-native';
import { useParams } from 'react-router';

import { getLocalizedTexts } from '../../Locales';
import color from '../../style/color';
import { font } from '../../style/text';
import { useWindowSize } from '../../style/utils';
import { containerStyles } from '../../style/container';
import { SettingKey } from '../../api/graphql/fragments/settings';
import { getPublicSetting } from '../../api/rest/settings';
import { OnlineOfferActivationInfo, fetchOnlineOfferActivationInfo } from '../../api/rest/offers';
import { logEventWithoutAuthentication } from '../../api/rest/events';
import { logAmplitudeEventWithoutAuthentication } from '../../lib/events/amplitudeEvents';
import { getHelpCenterUrl } from '../../lib/auth/region';
import FloatingIcon from '../../components/common/FloatingIcon';
import Button from '../../components/common/Button';
import OfferCashback from '../../components/home/offers/OfferCashback';
import {
    OfferActivationState,
    redirectToAffiliateLink,
    sleep,
    useAffiliateLinkUrl,
    useQueryParameters,
} from '../../lib/offers/onlineOffersActivation';
import { checkIsAdBlockerActivated } from '../../lib/common/adBlocker';

const logo = { uri: '/assets/images/logos/logo-with-title.png' };
const currencySymbol = { uri: '/assets/images/visualIdentity/currency-symbol-green.svg' };
const ticketIcon = { uri: '/assets/images/icons/ticket.svg' };
const giftIcon = { uri: '/assets/images/icons/gift-pink-tilted.svg' };
const checkMarkIconFilled = { uri: '/assets/images/icons/check-mark-green-filled-2.svg' };
const checkMarkIcon = { uri: '/assets/images/icons/check-mark-gray.svg' };
const errorIcon = { uri: '/assets/images/icons/error.svg' };
const cookieIcon = { uri: '/assets/images/icons/cookie.png' };

const MIN_LOADING_TIME_MS = 1500;
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 1000;

export enum AdBlockerWarningNavigationButtonType {
    retry = 'retry',
    continue = 'continue',
    help = 'help',
}

function OnlineOfferActivationPage() {
    const { userId, offerId, stackMode } = useParams<{ userId: string; offerId: string; stackMode?: string }>();
    const shouldUseDevStack: boolean = stackMode === 'dev';
    const {
        triggeringEventPlatform,
        triggeringEventType,
        triggeringEventTimestamp,
        redirectUrl,
        isTemporaryUser,
        hasAcceptedMerchantCookies,
    } = useQueryParameters();
    const [offerActivationInfo, setOfferActivationInfo] = React.useState<OnlineOfferActivationInfo | undefined>(undefined);
    React.useEffect(() => {
        fetchOnlineOfferActivationInfo(userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform).then(
            (info) => setOfferActivationInfo(info)
        );
    }, [userId, offerId, shouldUseDevStack, redirectUrl, triggeringEventPlatform]);
    const affiliateLinkUrl = useAffiliateLinkUrl({
        userId,
        isTemporaryUser,
        shouldUseDevStack,
        offerActivationInfo,
        triggeringEventPlatform,
        triggeringEventType,
        triggeringEventTimestamp,
    });
    const eventMetadata = { userId, triggeringEventPlatform, triggeringEventType, shouldUseDevStack, isTemporaryUser };
    const logAdBlockerDetectedEvents = useLogAdBlockerDetectedEvents(eventMetadata);
    const logClickedAdBlockerWarningButtonEvents = useLogClickedAdBlockerWarningButtonEvents(eventMetadata);
    const offerActivationState = useOfferActivationState({
        offerActivationInfo,
        affiliateLinkUrl,
        logAdBlockerDetectedEvents,
        hasAcceptedMerchantCookies,
    });
    if (offerActivationInfo && affiliateLinkUrl)
        return (
            <Container>
                <Image source={logo} style={styles.imageLogo} />
                <View style={styles.containerBody}>
                    {offerActivationState !== OfferActivationState.cookieWarningAcknowledgementRequired ? (
                        <OfferActivationStatus {...{ offerActivationState, offerActivationInfo }} />
                    ) : null}
                    {offerActivationState === OfferActivationState.adBlockerDetected ? (
                        <AdBlockerMessage {...{ logClickedAdBlockerWarningButtonEvents }} />
                    ) : null}
                    {offerActivationState === OfferActivationState.adBlockerDetected && Platform.OS === 'web' ? (
                        <AdBlockerWarningNavigationButtons
                            {...{ affiliateLinkUrl, offerActivationInfo, logClickedAdBlockerWarningButtonEvents }}
                        />
                    ) : null}
                    {!hasAcceptedMerchantCookies &&
                    offerActivationInfo.hasAcknowledgedWarningForCookieTracking &&
                    offerActivationState !== OfferActivationState.adBlockerDetected ? (
                        <CookieWarningWithoutAcknowledgmentRequired />
                    ) : null}
                    {offerActivationState === OfferActivationState.cookieWarningAcknowledgementRequired ? (
                        <CookieWarningWithAcknowledgmentRequired
                            {...{ userId, affiliateLinkUrl, offerActivationInfo, shouldUseDevStack }}
                        />
                    ) : null}
                </View>
                <View style={styles.containerFooter}>
                    {offerActivationState === OfferActivationState.adBlockerDetected && Platform.OS !== 'web' ? (
                        <AdBlockerWarningNavigationButtons
                            {...{ affiliateLinkUrl, offerActivationInfo, logClickedAdBlockerWarningButtonEvents }}
                        />
                    ) : null}
                </View>
            </Container>
        );
    else return <LoadingAnimation />;
}

export default OnlineOfferActivationPage;

function useLogClickedAdBlockerWarningButtonEvents({
    userId,
    triggeringEventPlatform,
    triggeringEventType,
    shouldUseDevStack,
    isTemporaryUser,
}: {
    userId: string;
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
    shouldUseDevStack: boolean;
    isTemporaryUser: boolean;
}) {
    return React.useCallback(
        async (buttonType: AdBlockerWarningNavigationButtonType) => {
            await Promise.all([
                logEventWithoutAuthentication(
                    userId,
                    {
                        type: 'clickedAdBlockerWarningButton',
                        payload: { buttonType, triggeringEventPlatform, triggeringEventType },
                    },
                    shouldUseDevStack
                ),
                logAmplitudeEventWithoutAuthentication(
                    {
                        name: 'Offers - Clicked Ad Blocker Warning Button',
                        properties: { buttonType, triggeringEventPlatform, triggeringEventType },
                    },
                    { userId: isTemporaryUser ? undefined : userId }
                ),
            ]);
        },
        [userId, triggeringEventPlatform, triggeringEventType, shouldUseDevStack, isTemporaryUser]
    );
}

function useLogAdBlockerDetectedEvents({
    userId,
    isTemporaryUser,
    shouldUseDevStack,
    triggeringEventPlatform,
    triggeringEventType,
}: {
    userId: string;
    isTemporaryUser: boolean;
    shouldUseDevStack: boolean;
    triggeringEventPlatform: string | undefined;
    triggeringEventType: string | undefined;
}) {
    return React.useCallback(async () => {
        await Promise.all([
            logEventWithoutAuthentication(
                userId,
                {
                    type: 'adBlockerDetected',
                    payload: { from: 'onlineOfferActivationPage', triggeringEventPlatform, triggeringEventType },
                },
                shouldUseDevStack
            ),
            logAmplitudeEventWithoutAuthentication(
                {
                    name: 'Offers - Ad Blocker Detected',
                    properties: { from: 'onlineOfferActivationPage', triggeringEventPlatform, triggeringEventType },
                },
                { userId: isTemporaryUser ? undefined : userId }
            ),
        ]);
    }, [userId, isTemporaryUser, shouldUseDevStack, triggeringEventPlatform, triggeringEventType]);
}

function useOfferActivationState({
    offerActivationInfo,
    affiliateLinkUrl,
    logAdBlockerDetectedEvents,
    hasAcceptedMerchantCookies,
}: {
    offerActivationInfo: OnlineOfferActivationInfo | undefined;
    affiliateLinkUrl: string | undefined;
    logAdBlockerDetectedEvents: () => Promise<void>;
    hasAcceptedMerchantCookies: boolean;
}): OfferActivationState {
    const shouldAcknowledgeCookieWarning: boolean =
        !hasAcceptedMerchantCookies &&
        (offerActivationInfo ? !offerActivationInfo.hasAcknowledgedWarningForCookieTracking : false);
    const [offerActivationState, setOfferActivationState] = React.useState<OfferActivationState>(
        OfferActivationState.loading
    );
    React.useEffect(() => {
        if (!offerActivationInfo || !affiliateLinkUrl) return;
        const onSuccess = () =>
            shouldAcknowledgeCookieWarning
                ? setTimeout(
                      () => setOfferActivationState(OfferActivationState.cookieWarningAcknowledgementRequired),
                      MIN_LOADING_TIME_MS
                  )
                : setTimeout(
                      () => affiliateLinkUrl && redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl }),
                      SUCCESS_MESSAGE_DISPLAY_TIME_MS
                  );
        const runChecks = async () => {
            const startTimestampMs = Date.now();
            const isAdBlockerActivated = await checkIsAdBlockerActivated();
            const checkDurationMs = Date.now() - startTimestampMs;
            await sleep(Math.max(MIN_LOADING_TIME_MS - checkDurationMs, 0));
            if (isAdBlockerActivated) {
                setOfferActivationState(OfferActivationState.adBlockerDetected);
                logAdBlockerDetectedEvents();
            } else {
                setOfferActivationState(OfferActivationState.succeeded);
                onSuccess();
            }
        };
        runChecks();
    }, [offerActivationInfo, shouldAcknowledgeCookieWarning, affiliateLinkUrl, logAdBlockerDetectedEvents]);
    return offerActivationState;
}

function LoadingAnimation() {
    return (
        <View style={styles.containerLoadingAnimation}>
            <ActivityIndicator color={color.frenchGray} size="large" />
        </View>
    );
}

function Container({ children }: { children: JSX.Element | (JSX.Element | null)[] | null }) {
    const size = useWindowSize();
    return (
        <Modal>
            <View style={[styles.container, { height: size.height, width: size.width }]}>{children}</View>
        </Modal>
    );
}

function OfferActivationStatus({
    offerActivationState,
    offerActivationInfo,
}: {
    offerActivationState: OfferActivationState;
    offerActivationInfo: OnlineOfferActivationInfo;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.offerActivationStatus;
    return (
        <View
            style={[
                styles.containerBox,
                offerActivationState === OfferActivationState.succeeded && styles.containerBoxGreen,
            ]}>
            <StatusIcon {...{ offerActivationState }} />
            <Text
                style={[
                    styles.textTitle,
                    offerActivationState === OfferActivationState.succeeded && styles.textTitleSucceeded,
                    offerActivationState === OfferActivationState.adBlockerDetected && styles.textTitleFailed,
                ]}>
                {offerActivationState === OfferActivationState.loading
                    ? texts.loading
                    : offerActivationState === OfferActivationState.succeeded
                    ? texts.succeeded
                    : texts.failed}
            </Text>
            <OfferDetails {...offerActivationInfo} />
        </View>
    );
}

function StatusIcon({ offerActivationState }: { offerActivationState: OfferActivationState }) {
    if (offerActivationState === OfferActivationState.loading)
        return (
            <View style={styles.containerFloatingIcons}>
                <FloatingIcon icon={currencySymbol} iconStyle={{ width: 20, height: 20 }} />
                <FloatingIcon icon={ticketIcon} offsetY={-10} />
                <FloatingIcon icon={giftIcon} />
            </View>
        );
    if (offerActivationState === OfferActivationState.succeeded)
        return <Image source={checkMarkIconFilled} style={styles.imageStatusIcon} />;
    return <Image source={errorIcon} style={styles.imageStatusIcon} />;
}

function OfferDetails(offerInfo: OnlineOfferActivationInfo | undefined) {
    if (offerInfo) {
        const partialOffer = offerInfo.partialOffer;
        return (
            <View style={styles.containerOfferDetails}>
                <View style={containerStyles.containerRowLeftCenter}>
                    <View style={styles.containerOfferLogo}>
                        <Image source={{ uri: partialOffer.card?.logoURL || '' }} style={styles.imageOfferLogo} />
                    </View>
                    <View style={styles.containerOfferTexts}>
                        <Text style={styles.textOfferTitle} numberOfLines={1}>
                            {partialOffer.card?.title}
                        </Text>
                        <OfferCashback {...{ offer: partialOffer, shouldUseValueMinCondition: true }} />
                    </View>
                </View>
            </View>
        );
    }
    return null;
}

function AdBlockerMessage({
    logClickedAdBlockerWarningButtonEvents,
}: {
    logClickedAdBlockerWarningButtonEvents: (buttonType: AdBlockerWarningNavigationButtonType) => Promise<void>;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.adBlockerMessage;
    const subtitle1 = texts.subtitle1;
    const subtitle2 = Platform.OS === 'web' ? texts.subtitle2OnDesktop : texts.subtitle2OnMobile;
    const shouldSkipLine: boolean = isDeviceScreenLarge;
    const subtitle = `${subtitle1}${shouldSkipLine ? '\n' : ' '}${subtitle2}`;
    return (
        <View style={[styles.containerBox, styles.containerAdBlockerMessage]}>
            <Text style={[styles.textTitle, styles.textAdBlockerMessageTitle]}>{texts.title}</Text>
            <Text style={[styles.textSubtitle, styles.textAdBlockerMessageSubtitle]}>{subtitle}</Text>
            <TouchableOpacity
                onPress={async () => {
                    logClickedAdBlockerWarningButtonEvents(AdBlockerWarningNavigationButtonType.help);
                    const shouldUseIntercom = await getPublicSetting<boolean>(
                        SettingKey.shouldUseIntercomWhenLoggedOutOnWebApp
                    );
                    const fallbackUrl = shouldUseIntercom ? getHelpCenterUrl(true) : getHelpCenterUrl(false);
                    const adBlockerFaqArticleUrl =
                        (await getPublicSetting<string>(SettingKey.adBlockerFaqLink)) || fallbackUrl;
                    window.open(adBlockerFaqArticleUrl);
                }}>
                <Text style={styles.textAdBlockerMessageSupportLink}>{texts.supportLink}</Text>
            </TouchableOpacity>
        </View>
    );
}

function AdBlockerWarningNavigationButtons({
    affiliateLinkUrl,
    offerActivationInfo,
    logClickedAdBlockerWarningButtonEvents,
}: {
    affiliateLinkUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
    logClickedAdBlockerWarningButtonEvents: (buttonType: AdBlockerWarningNavigationButtonType) => Promise<void>;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.adBlockerMessage.navigationButtons;
    return (
        <View style={styles.containerNavigationButtons}>
            {Platform.OS === 'web' ? (
                <Button
                    onPress={async () => {
                        await logClickedAdBlockerWarningButtonEvents(AdBlockerWarningNavigationButtonType.retry);
                        window.location.reload();
                    }}
                    style={styles.containerRetryButton}
                    textStyle={styles.textButton}>
                    {texts.retry}
                </Button>
            ) : null}
            <Button
                onPress={async () => {
                    await logClickedAdBlockerWarningButtonEvents(AdBlockerWarningNavigationButtonType.continue);
                    redirectToAffiliateLink({ affiliateLinkUrl, offerActivationInfo });
                }}
                style={styles.containerBackButton}
                textStyle={styles.textButton}
                useSecondaryColor>
                {texts.continue}
            </Button>
        </View>
    );
}

function CookieWarningWithoutAcknowledgmentRequired() {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.cookieWarning.withoutAcknowledgmentRequired;
    return (
        <View style={[styles.containerBox, styles.containerCookieWarningWithoutAcknowledgmentRequired]}>
            <Text style={[styles.textTitle, styles.textCookieWarningTitle]}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
        </View>
    );
}

function CookieWarningWithAcknowledgmentRequired({
    userId,
    affiliateLinkUrl,
    offerActivationInfo,
    shouldUseDevStack,
}: {
    userId: string;
    affiliateLinkUrl: string;
    offerActivationInfo: OnlineOfferActivationInfo;
    shouldUseDevStack: boolean;
}) {
    const texts = getLocalizedTexts().home.offers.onlineOfferActivation.cookieWarning.withAcknowledgmentRequired;
    const [isCheckboxFilled, setIsCheckboxFilled] = React.useState(false);
    return (
        <View style={[styles.containerBox, styles.containerBoxBlue]}>
            <Image source={cookieIcon} style={styles.imageCookieIcon} />
            <Text style={[styles.textTitle, styles.textCookieWarningTitle]}>{texts.title}</Text>
            <Text style={styles.textSubtitle}>{texts.subtitle}</Text>
            <Button
                onPress={async () => {
                    if (isCheckboxFilled) await skipFutureCookieWarningAcknowledgment(userId, shouldUseDevStack);
                    redirectToAffiliateLink({ offerActivationInfo, affiliateLinkUrl });
                }}
                style={styles.containerAcknowledgeCookieWarningButton}
                textStyle={styles.textButton}>
                {texts.acknowledgeButtonText}
            </Button>
            <TouchableOpacity
                onPress={() => setIsCheckboxFilled(!isCheckboxFilled)}
                style={styles.containerSkipCookieWarningAcknowledgment}>
                <View style={styles.containerSkipCookieWarningAcknowledgmentCheckbox}>
                    {isCheckboxFilled ? (
                        <View style={styles.containerCheckBox}>
                            <Image source={checkMarkIcon} style={styles.imageCheckBoxFilled} />
                        </View>
                    ) : (
                        <View style={styles.containerCheckBox} />
                    )}
                </View>
                <Text style={styles.textSkipCookieWarningAcknowledgment}>{texts.skipFutureAcknowledgmentText}</Text>
            </TouchableOpacity>
        </View>
    );
}

async function skipFutureCookieWarningAcknowledgment(userId: string, shouldUseDevStack: boolean) {
    await logEventWithoutAuthentication(userId, { type: 'acknowledgedWarningForCookieTracking' }, shouldUseDevStack);
}

const { width } = Dimensions.get('window');
const isDeviceScreenLarge = width >= 1280;

const LOGO_TOP_MARGIN = isDeviceScreenLarge ? 54 : 13;
const LOGO_HEIGHT = isDeviceScreenLarge ? 54 : 50;

const bigStyles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100vh',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerBody: {
        alignItems: 'center',
    },
    containerFooter: {
        height: LOGO_HEIGHT + LOGO_TOP_MARGIN,
        alignItems: 'flex-end',
    },
    containerBox: {
        width: 384,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: color.alabaster,
    },
    containerBoxGreen: {
        backgroundColor: color.hintOfGreen,
    },
    containerBoxBlue: {
        backgroundColor: color.tranquil,
    },
    containerAdBlockerMessage: {
        marginTop: 23,
        backgroundColor: color.sauvignon,
    },
    containerFloatingIcons: {
        marginTop: 22,
        flexDirection: 'row',
    },
    containerOfferDetails: {
        marginTop: 16,
        marginBottom: 24,
        width: 336,
        height: 72,
        padding: 8,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerOfferLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 56,
        height: 56,
        borderRadius: 56,
        backgroundColor: color.linkWaterLight,
    },
    containerOfferTexts: {
        flex: 1,
        marginLeft: 8,
        flexDirection: 'column',
    },
    containerNavigationButtons: {
        width: Platform.OS === 'web' ? 270 : 385,
        marginBottom: Platform.OS === 'web' ? 0 : 17,
    },
    containerRetryButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        marginTop: 28,
    },
    containerBackButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        marginTop: 9,
    },
    containerCookieWarningWithoutAcknowledgmentRequired: {
        marginTop: 23,
        backgroundColor: color.tranquil,
    },
    containerAcknowledgeCookieWarningButton: {
        height: Platform.OS === 'web' ? 40 : 56,
        width: Platform.OS === 'web' ? 241 : 384,
        marginBottom: 8,
    },
    containerSkipCookieWarningAcknowledgment: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
    },
    containerSkipCookieWarningAcknowledgmentCheckbox: {
        height: 18,
        width: 18,
        marginHorizontal: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerCheckBox: {
        height: 15,
        width: 15,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: color.frenchGray,
        backgroundColor: color.tranquil,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerLoadingAnimation: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
    imageLogo: {
        width: 114,
        marginTop: LOGO_TOP_MARGIN,
        height: LOGO_HEIGHT,
        resizeMode: 'contain',
    },
    imageStatusIcon: {
        marginTop: 25,
        width: 40,
        height: 40,
    },
    imageOfferLogo: {
        width: 36,
        height: 36,
        resizeMode: 'contain',
    },
    imageCookieIcon: {
        marginTop: 24,
        height: 58,
        width: 58,
        borderRadius: 58,
        backgroundColor: color.whiteIce,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageCheckBoxFilled: {
        height: 15,
        width: 15,
    },
    textTitle: {
        marginTop: 18,
        width: 336,
        fontFamily: font.ambitBlack,
        fontSize: Platform.OS === 'web' ? 24 : 20,
        color: color.black,
        textAlign: 'center',
    },
    textTitleSucceeded: {
        color: color.emerald,
    },
    textTitleFailed: {
        color: color.flamingo,
    },
    textAdBlockerMessageTitle: {
        fontSize: 18,
        color: color.flamingo,
    },
    textCookieWarningTitle: {
        fontSize: 18,
        color: color.easternBlue,
    },
    textOfferTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textSubtitle: {
        marginTop: 16,
        marginBottom: 24,
        width: 358,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
        textAlign: 'center',
    },
    textAdBlockerMessageSubtitle: {
        marginTop: 8,
        marginBottom: 10,
    },
    textAdBlockerMessageSupportLink: {
        marginBottom: 18,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.flamingo,
        textDecorationLine: 'underline',
    },
    textButton: {
        fontSize: 15,
    },
    textSkipCookieWarningAcknowledgment: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.manatee,
        textAlign: 'center',
    },
});

const smallStyles = StyleSheet.create({
    container: {
        height: '100vh',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerBody: {
        alignItems: 'center',
    },
    containerFooter: {
        height: LOGO_HEIGHT + LOGO_TOP_MARGIN,
    },
    containerBox: {
        marginTop: 9,
        width: 284,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        backgroundColor: color.alabaster,
    },
    containerBoxGreen: {
        backgroundColor: color.hintOfGreen,
    },
    containerBoxBlue: {
        backgroundColor: color.tranquil,
    },
    containerAdBlockerMessage: {
        marginTop: 17,
        backgroundColor: color.sauvignon,
    },
    containerFloatingIcons: {
        marginTop: 17,
        flexDirection: 'row',
    },
    containerOfferDetails: {
        marginTop: 10,
        marginBottom: 17,
        width: 262,
        height: 72,
        padding: 8,
        backgroundColor: color.white,
        borderRadius: 8,
    },
    containerOfferLogo: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 46,
        height: 46,
        borderRadius: 46,
        backgroundColor: color.linkWaterLight,
    },
    containerOfferTexts: {
        flex: 1,
        marginLeft: 8,
        flexDirection: 'column',
    },
    containerNavigationButtons: {
        width: Platform.OS === 'web' ? 241 : 298,
        marginBottom: Platform.OS === 'web' ? 0 : 17,
    },
    containerRetryButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        marginTop: 24,
    },
    containerBackButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        marginTop: 9,
    },
    containerCookieWarningWithoutAcknowledgmentRequired: {
        marginTop: 17,
        backgroundColor: color.tranquil,
    },
    containerAcknowledgeCookieWarningButton: {
        height: Platform.OS === 'web' ? 40 : 53,
        width: Platform.OS === 'web' ? 241 : 284,
        marginBottom: 8,
    },
    containerSkipCookieWarningAcknowledgment: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
    },
    containerSkipCookieWarningAcknowledgmentCheckbox: {
        height: 18,
        width: 18,
        marginHorizontal: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerCheckBox: {
        height: 15,
        width: 15,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: color.frenchGray,
        backgroundColor: color.tranquil,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerLoadingAnimation: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
    imageLogo: {
        width: 106,
        marginTop: LOGO_TOP_MARGIN,
        height: LOGO_HEIGHT,
        resizeMode: 'contain',
    },
    imageStatusIcon: {
        marginTop: 15,
        height: 30,
        width: 30,
    },
    imageCookieIcon: {
        marginTop: 17,
        height: 48,
        width: 48,
        borderRadius: 48,
        backgroundColor: color.whiteIce,
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageOfferLogo: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
    },
    imageCheckBoxFilled: {
        height: 10,
        width: 10,
    },
    textTitle: {
        marginTop: 13,
        width: 266,
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
        textAlign: 'center',
    },
    textTitleSucceeded: {
        color: color.emerald,
    },
    textTitleFailed: {
        color: color.flamingo,
    },
    textAdBlockerMessageTitle: {
        fontSize: 16,
        color: color.flamingo,
    },
    textCookieWarningTitle: {
        fontSize: 16,
        color: color.easternBlue,
    },
    textOfferTitle: {
        fontFamily: font.ambitBold,
        fontSize: 18,
        color: color.black,
    },
    textSubtitle: {
        marginTop: 12,
        marginBottom: 17,
        width: 250,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.black,
        textAlign: 'center',
    },
    textAdBlockerMessageSubtitle: {
        marginTop: 8,
        marginBottom: 10,
    },
    textAdBlockerMessageSupportLink: {
        marginBottom: 15,
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.flamingo,
        textDecorationLine: 'underline',
    },
    textButton: {
        fontSize: 14,
    },
    textSkipCookieWarningAcknowledgment: {
        fontFamily: font.ambitRegular,
        fontSize: 14,
        color: color.manatee,
        textAlign: 'center',
    },
});

const styles = isDeviceScreenLarge ? bigStyles : smallStyles;
