import React from 'react';
import styled, { keyframes } from 'styled-components';

export function SpinningWheel({ width, height }: { width: number; height: number }) {
    return <Wheel {...{ width, height }} />;
}

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Wheel = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    animation: ${spinnerAnimation} 0.8s linear infinite;
    margin: 0 auto;
`;
