import gql from 'graphql-tag';

import {
    UserProductDiscoveryConversationHistoryItemFragment,
    UserProductDiscoveryConversationHistoryItem,
    UserProductDiscoveryMessageWithProductsFragment,
    UserProductDiscoveryMessageWithProductsList,
} from '../fragments/productDiscovery';

export const userProductDiscoveryConversationsQuery = gql`
    query UserProductDiscoveryConversationsQuery($nextToken: String) {
        user {
            productDiscoveryConversations(nextToken: $nextToken) {
                items {
                    ...UserProductDiscoveryConversationHistoryItem
                }
                nextToken
            }
        }
    }
    ${UserProductDiscoveryConversationHistoryItemFragment}
`;

export interface UserProductDiscoveryConversationList {
    __typename: 'UserProductDiscoveryConversationList';
    items: UserProductDiscoveryConversationHistoryItem[];
    nextToken: string | null;
}

export interface UserProductDiscoveryConversationsQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        productDiscoveryConversations: UserProductDiscoveryConversationList;
    };
}

export interface UserProductDiscoveryConversationsQueryVariables {
    nextToken?: string;
}

export const userProductDiscoveryMessagesWithProductsQuery = gql`
    query UserProductDiscoveryMessagesWithProductsQuery($conversationId: String) {
        user {
            productDiscoveryConversationWithProducts(conversationId: $conversationId) {
                items {
                    ...UserProductDiscoveryMessageWithProducts
                }
                lastFetchedAt
                nextToken
            }
        }
    }
    ${UserProductDiscoveryMessageWithProductsFragment}
`;

export interface UserProductDiscoveryMessagesWithProductsQueryResponse {
    __typename: 'Query';
    user: {
        __typename: 'User';
        productDiscoveryConversationWithProducts: UserProductDiscoveryMessageWithProductsList;
    };
}

export interface UserProductDiscoveryMessagesWithProductsQueryVariables {
    conversationId: string;
}
