import React from 'react';

import { colors } from '../../tokens/colors/colors';

type Props = {
    isSelected?: boolean;
    orientation?: 'up' | 'down';
} & React.SVGProps<SVGSVGElement>;

function Thumb({ isSelected, orientation }: Props) {
    const [fill, setFill] = React.useState('none');
    React.useEffect(() => {
        if (isSelected) setFill(colors.background.tertiary);
        else setFill('none');
    }, [isSelected]);
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={() => {
                if (!isSelected) setFill(colors.background.subtle);
            }}
            onMouseLeave={() => {
                if (!isSelected) setFill('none');
            }}>
            <g id="thumb">
                <path
                    id="Vector"
                    d="M7.45028 1.96094C7.0007 1.96094 6.57957 2.18087 6.32265 2.54981L2.16866 8.51505C1.3886 9.63523 1.04093 11.0001 1.19003 12.357L1.50947 15.2639C1.64597 16.5061 2.62116 17.4901 3.8621 17.6377L5.94018 17.8849C7.52709 18.0736 9.13609 17.91 10.6525 17.4056C11.7139 17.0526 12.5641 16.247 12.9737 15.2061L14.6509 10.9439C14.732 10.7377 14.7881 10.5226 14.8179 10.303C15.0773 8.39171 13.3503 6.80828 11.4686 7.23227L7.64241 8.09442L8.78092 3.67809C9.00513 2.80839 8.34841 1.96092 7.45028 1.96094Z"
                    fill={fill}
                    stroke={colors.background.tertiary}
                    strokeWidth="2"
                    strokeLinejoin="round"
                    transform={orientation === 'down' ? 'rotate(180 8 10)' : undefined}
                />
            </g>
        </svg>
    );
}

export default Thumb;
