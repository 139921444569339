import { ApolloClient, FetchPolicy } from '@apollo/react-hooks';

import { Setting, SettingKey } from '../../api/graphql/fragments/settings';
import { settingQuery, SettingQueryResponse } from '../../api/graphql/queries/settings';

export async function getSetting(
    apolloClient: ApolloClient<object>,
    settingKey: SettingKey,
    fetchPolicy: FetchPolicy
): Promise<Setting | undefined> {
    const { data } = await apolloClient.query<SettingQueryResponse>({
        query: settingQuery,
        fetchPolicy,
    });
    const setting = data?.settings?.items.find(({ settingKey: key }) => key === settingKey);
    return setting;
}

const SETTINGS_QUERY_THROTTLING_IN_MS = 6 * 60 * 60 * 1000; // 6 hours

export async function getSettingFromLocalStorage(
    apolloClient: ApolloClient<object>,
    settingKey: SettingKey
): Promise<string | undefined> {
    const localStorageData = localStorage.getItem('settings');
    if (localStorageData) {
        const parsedData: { data: { [key: string]: { timestampInMs: number; value: string } } } =
            JSON.parse(localStorageData);
        const settingFromLocalStorage = parsedData.data[settingKey];
        if (settingFromLocalStorage && Date.now() - settingFromLocalStorage.timestampInMs < SETTINGS_QUERY_THROTTLING_IN_MS)
            return settingFromLocalStorage.value;
    }
    const setting = await getSetting(apolloClient, settingKey, 'network-only');
    const updatedLocalStorageData = localStorageData
        ? {
              data: {
                  ...JSON.parse(localStorageData).data,
                  [settingKey]: { timestampInMs: Date.now(), value: setting?.value },
              },
          }
        : { data: { [settingKey]: { timestampInMs: Date.now(), value: setting?.value } } };
    localStorage.setItem('settings', JSON.stringify(updatedLocalStorageData));
    return setting?.value;
}
