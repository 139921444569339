import * as React from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { useApolloClient } from '../../api/config';
import {
    UserProductDiscoveryConversationHistoryItem,
    UserProductDiscoveryMessageWithProducts,
} from '../../api/graphql/fragments/productDiscovery';
import { ApolloClient } from '../../api/graphql/client';
import {
    userProductDiscoveryConversationsQuery,
    UserProductDiscoveryConversationsQueryResponse,
    UserProductDiscoveryConversationsQueryVariables,
    userProductDiscoveryMessagesWithProductsQuery,
    UserProductDiscoveryMessagesWithProductsQueryResponse,
    UserProductDiscoveryMessagesWithProductsQueryVariables,
} from '../../api/graphql/queries/productDiscoveryConversations';
import { getLocalizedTexts } from '../../Locales';
import { capitalizeFirstLetter, isMobileDevice } from '../../style/utils';
import { ProductDiscoveryConversationMessageWithProducts } from './conversationMessages';

// We set a limit for conversations date for backwards compatibility since we didn't store the suggested products in the first conversations
const DATE_LIMIT_FOR_FETCHING_CONVERSATIONS = new Date('2024-12-02T10:00:00Z');

export function useProductDiscoveryConversations(): {
    productDiscoveryConversationsByDateMap: ProductDiscoveryConversationHistoryItemsByDate | undefined;
    refetch: () => Promise<ApolloQueryResult<UserProductDiscoveryConversationsQueryResponse>>;
    fetchMore: () => void;
    checkCanFetchMore: () => boolean;
    isFetchingMore: boolean;
} {
    const queryResponse = useQuery<
        UserProductDiscoveryConversationsQueryResponse,
        UserProductDiscoveryConversationsQueryVariables
    >(userProductDiscoveryConversationsQuery, {
        variables: {},
        fetchPolicy: 'cache-and-network',
    });
    const productDiscoveryConversationsByDateMap = React.useMemo(() => {
        const productDiscoveryConversations = queryResponse.data?.user?.productDiscoveryConversations?.items || undefined;
        const filteredProductDiscoveryConversations = productDiscoveryConversations?.filter(
            ({ createdAt }) => new Date(createdAt) > DATE_LIMIT_FOR_FETCHING_CONVERSATIONS
        );
        return groupConversationHistoryItemsByDate(filteredProductDiscoveryConversations || []);
    }, [queryResponse]);
    const [isFetchingMore, setIsFetchingMore] = React.useState(false);
    // We use the `fetchMore` function to fetch more conversations when the user scrolls to the bottom of the list
    const fetchMore = React.useCallback(async () => {
        const nextToken = queryResponse.data?.user?.productDiscoveryConversations?.nextToken;
        if (!nextToken || isFetchingMore) return;
        const nextTokenObject = JSON.parse(nextToken);
        if (!nextTokenObject?.createdAt) return;
        const nextTokenDate = new Date(nextTokenObject.createdAt);
        if (nextTokenDate < DATE_LIMIT_FOR_FETCHING_CONVERSATIONS) return;
        setIsFetchingMore(true);
        // It executes a query with the exact same shape and variables as the original query, and relies on `nextToken` to know when the pagination should resume
        await queryResponse.fetchMore<
            UserProductDiscoveryConversationsQueryResponse,
            UserProductDiscoveryConversationsQueryVariables,
            any
        >({
            variables: { nextToken },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                setIsFetchingMore(false);
                const previousItems = previousResult?.user?.productDiscoveryConversations?.items ?? [];
                const previousItemsIds = new Set(previousItems.map(({ conversationId }) => conversationId));
                const fetchMoreItems = fetchMoreResult?.user?.productDiscoveryConversations?.items ?? [];
                const filteredFetchMoreItems = fetchMoreItems.filter(
                    ({ conversationId }) => !previousItemsIds.has(conversationId)
                );
                return {
                    __typename: 'Query',
                    user: {
                        __typename: 'User',
                        productDiscoveryConversations: {
                            __typename: 'UserProductDiscoveryConversationList',
                            items: [...previousItems, ...filteredFetchMoreItems],
                            nextToken: fetchMoreResult?.user?.productDiscoveryConversations.nextToken ?? null,
                        },
                    },
                };
            },
        });
    }, [queryResponse]);
    const checkCanFetchMore = React.useCallback(() => {
        const nextToken = queryResponse.data?.user?.productDiscoveryConversations?.nextToken;
        return !!nextToken && !isFetchingMore;
    }, [queryResponse]);
    return {
        productDiscoveryConversationsByDateMap,
        refetch: queryResponse.refetch,
        fetchMore,
        checkCanFetchMore,
        isFetchingMore,
    };
}

type ProductDiscoveryConversationHistoryItemsByDate = {
    [date: string]: UserProductDiscoveryConversationHistoryItem[];
};

export const groupConversationHistoryItemsByDate = (
    productDiscoveryConversations: UserProductDiscoveryConversationHistoryItem[]
): ProductDiscoveryConversationHistoryItemsByDate => {
    const texts = getLocalizedTexts().productDiscovery.conversationHistoryDates;
    const dateGroups: { [date: string]: typeof productDiscoveryConversations } = {};
    const today = moment();
    const yesterday = moment().subtract(1, 'days');
    const lastMonth = moment().subtract(30, 'days');
    for (const item of productDiscoveryConversations) {
        const itemDate = moment(item.createdAt);
        if (itemDate.isSame(today, 'day')) dateGroups[texts.today] = [...(dateGroups[texts.today] || []), item];
        else if (itemDate.isSame(yesterday, 'day'))
            dateGroups[texts.yesterday] = [...(dateGroups[texts.yesterday] || []), item];
        else if (itemDate.isAfter(lastMonth)) dateGroups[texts.lastMonth] = [...(dateGroups[texts.lastMonth] || []), item];
        else if (itemDate.isSame(today, 'year')) {
            const monthName = capitalizeFirstLetter(itemDate.format('MMMM'));
            dateGroups[monthName] = [...(dateGroups[monthName] || []), item];
        } else {
            const year = itemDate.format('YYYY');
            dateGroups[year] = [...(dateGroups[year] || []), item];
        }
    }
    return dateGroups;
};

export async function runProductDiscoveryConversationsQuery(
    refetch: () => Promise<ApolloQueryResult<UserProductDiscoveryConversationsQueryResponse>>
) {
    try {
        const queryResponse = await refetch();
        const productDiscoveryConversations = queryResponse.data?.user?.productDiscoveryConversations?.items || undefined;
        if (!productDiscoveryConversations?.length) return;
        const lastConversationId = productDiscoveryConversations[0].conversationId;
        window.history.pushState({}, '', `/product-discovery/${lastConversationId}`);
    } catch (error) {
        console.log('Error while running product discovery conversations query', error);
    }
}

export function updateProductDiscoveryConversationMessageWithProductsListInCache(
    client: ApolloClient,
    conversationId: string,
    isSandboxEnvironment: boolean,
    productDiscoveryConversationMessageWithProducts: UserProductDiscoveryMessageWithProducts[]
) {
    let valueInCache = undefined;
    try {
        valueInCache = client.readQuery<UserProductDiscoveryMessagesWithProductsQueryResponse>({
            query: userProductDiscoveryMessagesWithProductsQuery,
            variables: { conversationId },
        });
    } catch (error) {
        if (isSandboxEnvironment) console.log('Error while fetching messages with products in cache', error);
    }
    client.writeQuery<UserProductDiscoveryMessagesWithProductsQueryResponse>({
        query: userProductDiscoveryMessagesWithProductsQuery,
        variables: { conversationId },
        data: {
            __typename: 'Query',
            user: {
                __typename: 'User',
                productDiscoveryConversationWithProducts: {
                    __typename: 'UserProductDiscoveryMessageWithProductsList',
                    items: productDiscoveryConversationMessageWithProducts,
                    lastFetchedAt: valueInCache?.user?.productDiscoveryConversationWithProducts?.lastFetchedAt || null,
                    nextToken: null,
                },
            },
        },
    });
}

export function useFetchMessagesOnConversationReopen({
    selectedConversationHistoryItemId,
    resetConversation,
    inputTextRef,
    setFailedToFetchConversation,
}: {
    selectedConversationHistoryItemId: string | undefined;
    resetConversation: ({
        conversationToReopen,
    }: {
        conversationToReopen?: {
            conversationId: string;
            messages: ProductDiscoveryConversationMessageWithProducts[];
        };
    }) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement | null>;
    setFailedToFetchConversation: (value: boolean) => void;
}) {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        // We fetch the messages for the selected conversation history item and reset the conversation with the fetched messages
        async function getProductDiscoveryMessagesWithProductsFromPastConversation() {
            if (!apolloClient || !selectedConversationHistoryItemId) return;
            const messages = await getProductDiscoveryMessagesWithProducts(apolloClient, selectedConversationHistoryItemId);
            if (!messages?.length) {
                setFailedToFetchConversation(true);
                return;
            }
            resetConversation({ conversationToReopen: { conversationId: selectedConversationHistoryItemId, messages } });
            if (!isMobileDevice) inputTextRef.current?.focus();
        }
        getProductDiscoveryMessagesWithProductsFromPastConversation();
    }, [apolloClient, selectedConversationHistoryItemId]);
}

const USER_MESSAGES_WITH_PRODUCTS_QUERY_REFETCH_FREQUENCY_IN_MS = 12 * 60 * 60 * 1000; // 12 hours

async function getProductDiscoveryMessagesWithProducts(
    apolloClient: ApolloClient,
    conversationId: string
): Promise<ProductDiscoveryConversationMessageWithProducts[] | undefined> {
    let queryResponse = await apolloClient.query<
        UserProductDiscoveryMessagesWithProductsQueryResponse,
        UserProductDiscoveryMessagesWithProductsQueryVariables
    >({
        query: userProductDiscoveryMessagesWithProductsQuery,
        variables: { conversationId },
        fetchPolicy: 'cache-first',
    });
    const lastFetchedAt = queryResponse.data?.user?.productDiscoveryConversationWithProducts?.lastFetchedAt;
    if (!lastFetchedAt || lastFetchedAt < Date.now() - USER_MESSAGES_WITH_PRODUCTS_QUERY_REFETCH_FREQUENCY_IN_MS) {
        queryResponse = await apolloClient.query<
            UserProductDiscoveryMessagesWithProductsQueryResponse,
            UserProductDiscoveryMessagesWithProductsQueryVariables
        >({
            query: userProductDiscoveryMessagesWithProductsQuery,
            variables: { conversationId },
            fetchPolicy: 'network-only',
        });
    }
    const productDiscoveryConversationWithProducts =
        queryResponse.data?.user?.productDiscoveryConversationWithProducts?.items || undefined;
    return productDiscoveryConversationWithProducts.map((item, index) => ({
        ...item,
        messagePosition:
            item.messageId && item.messageId.split('|').length === 2
                ? item.messageId.split('|')[1].toString()
                : index.toString(),
        merchantProductOffers: item.merchantProductOffers || undefined,
    }));
}

const SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY = 'shouldShowConversationHistory';

export function useShouldShowConversationHistory() {
    const [shouldShowConversationHistory, setShouldShowConversationHistoryState] = React.useState(
        localStorage.getItem(SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY) === 'true'
    );
    const setShouldShowConversationHistory = React.useCallback((value: boolean) => {
        setShouldShowConversationHistoryState(value);
        localStorage.setItem(SHOULD_SHOW_CONVERSATION_HISTORY_STORAGE_KEY, value.toString());
    }, []);
    return { shouldShowConversationHistory, setShouldShowConversationHistory };
}
